import * as React from 'react';
import {ReactNode} from 'react';
import "./NumericInput.scss";
import {useTranslation} from "react-i18next";
import {numberUtils} from "../../../utils/numberUtils";

export enum NumericInputType {
    INTEGER_NUMBER,
    NUMBER,
    UNSIGNED_NUMBER,
    UNSIGNED_PERCENTAGE_NUMBER,
    SIGNED_PERCENTAGE_NUMBER,
}

const {decimal: initialDecimal} = numberUtils.getSeparators();
const percentagePattern = (decimal: string) => new RegExp(`(^100([${decimal}]0{1,2})?$)|(^([1-9]([0-9])?|0)([${decimal}][0-9]{0,3})?$)`, 'g');
const numberPattern = (decimal: string) => new RegExp(`^[+-]?([\\d]{0,5}[${decimal}])?\\d{0,4}$`, 'g');
const unsignedNumberPattern = (decimal: string) => new RegExp(`^[\\d]{0,12}[${decimal}]?\\d{0,2}$`, 'g');

const REGEX_CHECK = {
    [NumericInputType.INTEGER_NUMBER]: /^[\d]{0,12}$/g,
    [NumericInputType.NUMBER]: numberPattern(initialDecimal),
    [NumericInputType.UNSIGNED_NUMBER]: unsignedNumberPattern(initialDecimal),
    [NumericInputType.UNSIGNED_PERCENTAGE_NUMBER]: percentagePattern(initialDecimal),
    [NumericInputType.SIGNED_PERCENTAGE_NUMBER]: percentagePattern(initialDecimal),
};

interface Props {
    type?: string,
    value?: any,
    placeholder?: string,
    title?: string | ReactNode,
    symbol?: any,
    inputType?: NumericInputType
    maxLength?: number
    editable?: boolean,
    disableInput?: boolean | null,
    className?: string,
    titleStyle?: any
    onChange?(value: any): void,
    onBlur?(event: any): any,
    onFocus?(event: any): any,
    onKeyDown?(event: any): any,
    reference?: (input: any) => any
    onFormatError?: (input: boolean | null) => void
    delayedOnChange?: boolean
}

// these type of inputs have updater for displayValue which are triggered when user finished entering value
// the format update is done in handleOnBlur
// while user entering value it is managed as string both in parent and here (value and displayValue)
// when user finished we safe in displayValue the formatted string, and in value (on parent side) we save number
const numberFormatter: { [index: string]: any } = {
    [NumericInputType.NUMBER]: (text: any) => (text === null || text === undefined || text === "") ? text : numberUtils.reverseAndFormatToLocaleNumber(text, 4),
    [NumericInputType.UNSIGNED_NUMBER]: (text: any) => (text === null || text === undefined || text === "") ? text : numberUtils.reverseAndFormatToLocaleNumber(text, 2),
    [NumericInputType.UNSIGNED_PERCENTAGE_NUMBER]: (text: any) => (text === null || text === undefined || text === "") ? text : numberUtils.reverseAndFormatToLocaleNumber(text, 3),
    [NumericInputType.SIGNED_PERCENTAGE_NUMBER]: (text: any) => (text === null || text === undefined || text === "") ? text : numberUtils.reverseAndFormatToLocaleNumber(text, 3, true)
};

const handleChange = ({
                          disableInput,
                          editableValue,
                          event,
                          inputType,
                          setDisplayValue,
                          onChange,
                          delayedOnChange,
                          maxLength
                      }: any) => {
    if (disableInput === false && editableValue === true) {
        let text = event.target.value;
        const pattern = REGEX_CHECK[inputType as NumericInputType];
        if (!!numberFormatter[inputType]) {
            const {decimal} = numberUtils.getSeparators();
            text = text.replace(new RegExp('[,.]', 'g'), decimal);
            if (text.split(decimal)?.[0]?.length > maxLength) {
                return;
            }
        }
        let textMatchesPattern = text.match(pattern);
        if (inputType === NumericInputType.SIGNED_PERCENTAGE_NUMBER) {
            const noSignText = text
                .replace(new RegExp('\\+', 'g'), '')
                .replace(new RegExp('\\-', 'g'), '');
            textMatchesPattern = !noSignText || noSignText.match(pattern);
        }
        if (textMatchesPattern || text === "") {
            setDisplayValue(text);
            if (delayedOnChange) {
                timerDelayedInput && clearTimeout(timerDelayedInput);
                timerDelayedInput = setTimeout(() => {
                    onChange?.(text?.trim());
                }, 400);
            } else onChange?.(text?.trim());
        }
    }
};

const handleOnFocus = ({
                           disableInput,
                           editableValue,
                           inputType,
                           setDisplayValue,
                           setFocused,
                           parentValue,
                           onFocus,
                           event
                       }: any) => {
    const {group, decimal} = numberUtils.getSeparators();
    if (disableInput === false && editableValue === true) {
        if (!!numberFormatter[inputType] && typeof parentValue === "string") {
            parentValue = parentValue.replace(new RegExp(`[${group}]`, 'g'), '');
        }
        if (parentValue && inputType === NumericInputType.SIGNED_PERCENTAGE_NUMBER) {
            if (!parentValue.toString().includes("-")) {
                parentValue = "+" + parentValue;
            }
        }
        if (!!numberFormatter[inputType]) parentValue = parentValue?.toString().replace(new RegExp('[,.]', 'g'), decimal);
        setDisplayValue(parentValue);
        onFocus && onFocus(event);
    }
    setFocused(true);
};

const handleOnBlur = ({
                          disableInput,
                          editableValue,
                          inputType,
                          onChange,
                          setFocused,
                          event,
                          onBlur,
                      }: any) => {
    setFocused(false);
    let value = event?.target?.value;
    if (disableInput === false && editableValue === true) {
        //inside we show formatted value if there is formatter
        if (!!numberFormatter[inputType]) {
            // const formatted = numberFormatter[inputType](value);
            // setDisplayValue(formatted);
            if (typeof value === 'string' && !isNaN(parseFloat(value))) {
                value = value.replace?.(new RegExp('[,.]', 'g'), '.'); //'.' is decimal separator for JS
                (onBlur || onChange)?.(parseFloat(value));
            }
        } else {
            onBlur?.(value);
            //outside we always save original value
            onChange?.(value);
        }
    }
};
let timerDelayedInput: any;

const NumericInput: React.FC<Props> = ({
                                           onBlur,
                                           editable = true,
                                           type = "text",
                                           symbol,
                                           value: parentValue,
                                           title,
                                           placeholder,
                                           inputType = NumericInputType.INTEGER_NUMBER,
                                           onChange,
                                           maxLength = 10,
                                           onFocus,
                                           onKeyDown,
                                           disableInput = false,
                                           titleStyle = "",
                                           reference,
                                           className,
                                           delayedOnChange
                                       }) => {

    const {t} = useTranslation();
    const [editableValue, setEditableValue] = React.useState(!parentValue);
    const [displayValue, setDisplayValue] = React.useState<any>();
    const [focused, setFocused] = React.useState<boolean>(false);

    React.useEffect(() => {
        //we align the displayValue with formatted parentValue otherwise with raw parentValue
        //we do it only when the input is not focused
        //because while it is focused we handle and show the numeric inputs as numbers
        //and not formatted strings
        if (!focused) {
            setDisplayValue(numberFormatter[inputType]?.(parentValue) || parentValue);
        }
        // eslint-disable-next-line
    }, [parentValue, displayValue, focused]);


    React.useEffect(() => {
        //clear timer garbage
        if (delayedOnChange) return () => clearTimeout(timerDelayedInput);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        setEditableValue(editable);
        // eslint-disable-next-line
    }, [editable]);

    React.useEffect(() => {
        const {decimal} = numberUtils.getSeparators();
        REGEX_CHECK[NumericInputType.NUMBER] = numberPattern(decimal);
        REGEX_CHECK[NumericInputType.UNSIGNED_NUMBER] = unsignedNumberPattern(decimal);
        REGEX_CHECK[NumericInputType.UNSIGNED_PERCENTAGE_NUMBER] = percentagePattern(decimal);
        REGEX_CHECK[NumericInputType.SIGNED_PERCENTAGE_NUMBER] = percentagePattern(decimal);
    }, [t]);

    if (inputType === NumericInputType.NUMBER) placeholder = placeholder || "+/-" + numberUtils.getNumberPlaceholder();
    if (inputType === NumericInputType.UNSIGNED_NUMBER) placeholder = placeholder || numberUtils.getNumberPlaceholder();
    if (inputType === NumericInputType.SIGNED_PERCENTAGE_NUMBER) {
        placeholder = placeholder || ("+/-" + numberUtils.getRatePlaceholder());
    }
    if (inputType === NumericInputType.UNSIGNED_PERCENTAGE_NUMBER) {
        placeholder = placeholder || numberUtils.getRatePlaceholder();
    }
    return <>
        {title && <div className="customInput__titleContainer">
            <div className={`customInput__title ${titleStyle}`}>{title}</div>
        </div>}
        <div className={`customInput__container ${className} ${disableInput === true && "customInput__container--outlineNone"}`}>

            <label className="customInput__inputContainer">
                <input
                    title={displayValue}
                    type={type}
                    placeholder={placeholder}
                    onBlur={(event) => handleOnBlur({
                        disableInput,
                        editableValue,
                        inputType,
                        onChange,
                        setDisplayValue,
                        setFocused,
                        event,
                        onBlur,
                    })}
                    onFocus={(event) => handleOnFocus({
                        disableInput,
                        editableValue,
                        inputType,
                        setDisplayValue,
                        setFocused,
                        parentValue,
                        onFocus,
                        event
                    })}
                    value={displayValue ?? ""}
                    onChange={(event) => handleChange({
                        disableInput,
                        editableValue,
                        event,
                        inputType,
                        onChange,
                        setDisplayValue,
                        delayedOnChange,
                        maxLength
                    })}
                    className={`customInput__input ${disableInput === true && "customInput__input--disabled"}`}
                    maxLength={!numberFormatter[inputType] ? maxLength : undefined}
                    onKeyDown={onKeyDown}
                    ref={reference}
                />
                {symbol}
            </label>

            {!editableValue &&
            <div className="customInput__modify" onClick={() => setEditableValue(true)}>
                {t("common.button.modify")}
            </div>
            }
        </div>
    </>;
};

export default React.memo(NumericInput);

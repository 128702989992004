import {CompanyAction} from "./company-interface";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {CompanyDetailsDTO, DeleteEffectiveOwnerRequest, GetCompanyDataDTORequest, UpdateLegalRepresentativeEffectiveOwnerRequest} from "../../model/CompanyDTO";
import {ADD_LEGAL_REPRESENTATIVE, ADD_LEGAL_REPRESENTATIVE_FAIL, ADD_LEGAL_REPRESENTATIVE_SUCCESS, GET_COMPANY_DATA_FAIL, GET_COMPANY_DATA_REQUEST, GET_COMPANY_DATA_SUCCESS, GET_COMPANY_INFO, GET_COMPANY_INFO_FAIL, GET_COMPANY_INFO_SUCCESS, GET_COMPANY_LIST_FAIL, GET_COMPANY_LIST_REQUEST, GET_COMPANY_LIST_SUCCESS, GET_COMPANY_MONEYLENDER_FAIL, GET_COMPANY_MONEYLENDER_REQUEST, GET_COMPANY_MONEYLENDER_SUCCESS, GET_INVOICE_HISTORY, GET_INVOICE_HISTORY_FAIL, GET_INVOICE_HISTORY_SUCCESS, GET_MARKET_DATA, GET_MARKET_DATA_FAIL, GET_MARKET_DATA_SUCCESS, GET_NEW_PROGRAMS, GET_NEW_PROGRAMS_FAIL, GET_NEW_PROGRAMS_SUCCESS, GET_NEW_VENDORS, GET_NEW_VENDORS_FAIL, GET_NEW_VENDORS_SUCCESS, REMOVE_EFFECTIVE_OWNER, REMOVE_EFFECTIVE_OWNER_FAIL, REMOVE_EFFECTIVE_OWNER_SUCCESS, REMOVE_LEGAL, REMOVE_LEGAL_FAIL, REMOVE_LEGAL_SUCCESS, SET_COMPANY_DETAILS_FAIL, SET_COMPANY_DETAILS_REQUEST, SET_COMPANY_DETAILS_SUCCESS, SWAP_USER_FAIL, SWAP_USER_REQUEST, SWAP_USER_SUCCESS,} from "./company-reducers";
import {errorExtractor, handleResponse} from "../reduxUtils";
import {companyService} from "../../service/CompanyService";
import {SET_ACTIVE_OWNERS, SET_ONBOARDING_LR_DATA} from "../auth/auth-reducers";
import {AuthAction} from "../auth/auth-interface";
import {Roles} from "../../model/UserDTO";
import {SwapUserRequestDTO} from "../../model/AdminDTO";


export const getCompanyList = (role: Roles): ThunkAction<void, {}, {}, CompanyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CompanyAction>): Promise<CompanyAction> => {
        dispatch({type: GET_COMPANY_LIST_REQUEST, payload: null});
        const response = companyService.getCompanyList(role);
        return handleResponse(dispatch, GET_COMPANY_LIST_SUCCESS, GET_COMPANY_LIST_FAIL, response, errorExtractor);
    };

export const getCompanyData = (data: GetCompanyDataDTORequest): ThunkAction<void, {}, {}, CompanyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CompanyAction>): Promise<CompanyAction> => {
        dispatch({type: SET_COMPANY_DETAILS_SUCCESS, payload: {} as CompanyDetailsDTO});
        dispatch({type: GET_COMPANY_DATA_REQUEST, payload: null});
        const response = companyService.getCompanyData(data);
        return handleResponse(dispatch, GET_COMPANY_DATA_SUCCESS, GET_COMPANY_DATA_FAIL, response, errorExtractor);
    };

export const setCompanyDetails = (vatNumber: string, data: CompanyDetailsDTO): ThunkAction<void, {}, {}, CompanyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CompanyAction>): Promise<CompanyAction> => {
        dispatch({type: SET_COMPANY_DETAILS_REQUEST, payload: null});
        const response = companyService.setCompanyDetails(vatNumber, data);
        return handleResponse(dispatch, SET_COMPANY_DETAILS_SUCCESS, SET_COMPANY_DETAILS_FAIL, response, errorExtractor);
    };

export const addLegalRepresentative = (vatNumber: string, data: UpdateLegalRepresentativeEffectiveOwnerRequest): ThunkAction<void, {}, {}, CompanyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction | AuthAction> => {
        dispatch({type: ADD_LEGAL_REPRESENTATIVE, payload: null});
        const response = companyService.addLegalRepresentative(vatNumber, data);
        let success;
        if (data.isUserEffectiveOwner || data.isLegalEffectiveOwner || data.effectiveOwner) {
            success = await handleResponse(dispatch, SET_ACTIVE_OWNERS, ADD_LEGAL_REPRESENTATIVE_FAIL, response, errorExtractor);
            success && dispatch({type: ADD_LEGAL_REPRESENTATIVE_SUCCESS, payload: null});
        } else {
            success = await handleResponse(dispatch, ADD_LEGAL_REPRESENTATIVE_SUCCESS, ADD_LEGAL_REPRESENTATIVE_FAIL, response, errorExtractor);
            success && dispatch({type: SET_ONBOARDING_LR_DATA, payload: {startDuty: data.startDuty, endDuty: data.endDuty}});
        }
        return success;
    };

export const deleteLegalRepresentative = (vatNumber: string): ThunkAction<void, {}, {}, CompanyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction | AuthAction> => {
        dispatch({type: REMOVE_LEGAL, payload: null});
        const response = companyService.deleteLegalRepresentative(vatNumber);
        return handleResponse(dispatch, REMOVE_LEGAL_SUCCESS, REMOVE_LEGAL_FAIL, response, errorExtractor);
    };

export const deleteEffectiveOwner = (vatNumber: string, email: DeleteEffectiveOwnerRequest): ThunkAction<void, {}, {}, CompanyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction | AuthAction> => {
        dispatch({type: REMOVE_EFFECTIVE_OWNER, payload: null});
        const response = companyService.deleteEffectiveOwner(vatNumber, email);
        const success = handleResponse(dispatch, SET_ACTIVE_OWNERS, REMOVE_EFFECTIVE_OWNER_FAIL, response, errorExtractor);
        success && dispatch({type: REMOVE_EFFECTIVE_OWNER_SUCCESS, payload: null});
        return success;
    };

export const getAllBuyersForMoneylender = (): ThunkAction<void, {}, {}, CompanyAction> =>
    async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction> => {
        dispatch({type: GET_COMPANY_MONEYLENDER_REQUEST, payload: null});
        const response = companyService.getAllBuyersForMoneylender();
        return handleResponse(dispatch, GET_COMPANY_MONEYLENDER_SUCCESS, GET_COMPANY_MONEYLENDER_FAIL, response, errorExtractor);
    };

export const getCompanyInfo = (vatNumber: string, email?: string): ThunkAction<void, {}, {}, CompanyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction> => {
        dispatch({type: GET_COMPANY_INFO, payload: null});
        const response = companyService.getCompanyInfo(vatNumber,email);
        return handleResponse(dispatch, GET_COMPANY_INFO_SUCCESS, GET_COMPANY_INFO_FAIL, response, errorExtractor);
    };
};

export const getNewCustomers = (role?: Roles): ThunkAction<void, {}, {}, CompanyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction> => {
        dispatch({type: GET_NEW_VENDORS, payload: null});
        const response = companyService.getNewCustomers(role);
        return handleResponse(dispatch, GET_NEW_VENDORS_SUCCESS, GET_NEW_VENDORS_FAIL, response, errorExtractor);
    };
};
export const getInvoiceHistory = (): ThunkAction<void, {}, {}, CompanyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction> => {
        dispatch({type: GET_INVOICE_HISTORY, payload: null});
        const response = companyService.getInvoiceHistory();
        return handleResponse(dispatch, GET_INVOICE_HISTORY_SUCCESS, GET_INVOICE_HISTORY_FAIL, response, errorExtractor);
    };
};

export const getNewPrograms = (): ThunkAction<void, {}, {}, CompanyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction> => {
        dispatch({type: GET_NEW_PROGRAMS, payload: null});
        const response = companyService.getNewPrograms();
        return handleResponse(dispatch, GET_NEW_PROGRAMS_SUCCESS, GET_NEW_PROGRAMS_FAIL, response, errorExtractor);
    };
};

export const getMarketData = (): ThunkAction<void, {}, {}, CompanyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CompanyAction | AuthAction>): Promise<CompanyAction> => {
        dispatch({type: GET_MARKET_DATA, payload: null});
        const response = companyService.getMarketData();
        return handleResponse(dispatch, GET_MARKET_DATA_SUCCESS, GET_MARKET_DATA_FAIL, response, errorExtractor);
    };
};

export const swapUsers = (data:SwapUserRequestDTO): ThunkAction<void, {}, {}, CompanyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, CompanyAction>): Promise<CompanyAction> => {
        dispatch({type: SWAP_USER_REQUEST, payload: null});
        const response = companyService.swapUser(data);
        return handleResponse(dispatch, SWAP_USER_SUCCESS, SWAP_USER_FAIL, response, errorExtractor);
    };
};

import {
    BuyerAction,
    BuyerState,
    GET_BUYER_ACTIVE_INVOICES,
    GET_BUYER_ACTIVE_INVOICES_FAIL,
    GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT,
    GET_BUYER_ACTIVE_INVOICES_SILENT, GET_BUYER_ACTIVE_INVOICES_SUCCESS,
    GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT,
    GET_BUYERS_AMOUNT_INVOICES,
    GET_BUYERS_AMOUNT_INVOICES_REQUEST,
    GET_BUYERS_INVOICES,
    GET_BUYERS_INVOICES_DELAYED,
    GET_BUYERS_INVOICES_DELAYED_REQUEST,
    GET_BUYERS_INVOICES_FAIL,
    GET_BUYERS_INVOICES_REQUEST,
    GET_CURRENCIES,
    GET_CURRENCIES_FAIL,
    GET_CURRENCIES_REQUEST,
    GET_DYNAMIC_DISCOUNT_ASSOCIATED,
    GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL,
    GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS
} from "./buyer-interface";
import {BuyerActiveInvoiceDTO, BuyerInvoiceDTO} from "../../model/BuyerDTO";
import {onlyUniqueElements} from "../../utils/commonUtils";

const defaultState: BuyerState = {
    invoicesCurrencies: [] as string[],
    invoicesCurrenciesIsFetching: false,
    invoicesCurrenciesFail: "",
    invoices: null,
    invoicesSelected: null,
    invoicesDelayed: null,
    computedInvoicesCurrencies: [] as string[],
    invoicesIsFetching: null,
    invoicesFail: "",
    isFetchingDynamicDiscountAssociated: null,
    getDynamicDiscountAssociatedSuccess: null,
    getDynamicDiscountAssociatedFail: "",
    activeInvoice: [],
    isFetchingActiveInvoices: false,
    isFetchingActiveInvoicesSilent: false,
    computedCurrencyFilters: [],
};

const computedInvoicesCurrencies = (action: BuyerAction) => ((action && action.payload && Array.isArray(action.payload)) &&
    action.payload.map((item: BuyerInvoiceDTO) => item.currency).filter(onlyUniqueElements)) || [] as string[];

const getCurrenciesFilters = (invoicesData: BuyerActiveInvoiceDTO[]) => invoicesData?.map?.((item) => item.currency).filter(onlyUniqueElements) || [];

export function buyerReducer(state = defaultState, action: BuyerAction): BuyerState {
    switch (action.type) {
        case GET_BUYERS_INVOICES:
            return {
                ...state,
                invoices: action.payload,
                computedInvoicesCurrencies: computedInvoicesCurrencies(action),
                invoicesIsFetching: false,
                invoicesFail: "",
            };
        case GET_BUYERS_AMOUNT_INVOICES:
            action.payload.forEach((item: BuyerInvoiceDTO) => item.selected = true);
            return {
                ...state,
                invoicesSelected: action.payload,
                invoicesIsFetching: false,
                invoicesFail: "",
            };
        case GET_BUYERS_INVOICES_DELAYED:
            return {
                ...state,
                invoicesDelayed: action.payload,
                computedInvoicesCurrencies: computedInvoicesCurrencies(action),
                invoicesIsFetching: false,
                invoicesFail: "",
            };
        case GET_BUYERS_INVOICES_REQUEST:
            return {
                ...state,
                invoices: defaultState.invoices,
                invoicesSelected: defaultState.invoicesSelected,
                invoicesDelayed: defaultState.invoicesDelayed,
                invoicesIsFetching: true,
                invoicesFail: "",
            };
        case GET_BUYERS_INVOICES_DELAYED_REQUEST:
        case GET_BUYERS_AMOUNT_INVOICES_REQUEST:
            return {
                ...state,
                invoicesSelected: defaultState.invoicesSelected,
                invoicesDelayed: defaultState.invoicesDelayed,
                invoicesIsFetching: true,
                invoicesFail: "",
            };

        case GET_BUYERS_INVOICES_FAIL:
            return {
                ...state,
                invoices: defaultState.invoices,
                invoicesIsFetching: false,
                invoicesFail: action.payload,
            };
        case GET_CURRENCIES:
            return {
                ...state,
                invoicesCurrencies: action.payload,
                invoicesCurrenciesIsFetching: false,
                invoicesCurrenciesFail: "",
            };
        case GET_CURRENCIES_REQUEST:
            return {
                ...state,
                invoicesCurrencies: defaultState.invoicesCurrencies,
                invoicesCurrenciesIsFetching: true,
                invoicesCurrenciesFail: "",
            };
        case GET_CURRENCIES_FAIL:
            return {
                ...state,
                invoicesCurrencies: defaultState.invoicesCurrencies,
                invoicesCurrenciesIsFetching: false,
                invoicesCurrenciesFail: action.payload,
            };
        case GET_DYNAMIC_DISCOUNT_ASSOCIATED:
            return {
                ...state,
                isFetchingDynamicDiscountAssociated: true,
                getDynamicDiscountAssociatedSuccess: defaultState.getDynamicDiscountAssociatedSuccess,
                getDynamicDiscountAssociatedFail: defaultState.getDynamicDiscountAssociatedFail,
            };
        case GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS:
            return {
                ...state,
                isFetchingDynamicDiscountAssociated: false,
                getDynamicDiscountAssociatedSuccess: action.payload,
                getDynamicDiscountAssociatedFail: defaultState.getDynamicDiscountAssociatedFail,
            };
        case GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL:
            return {
                ...state,
                isFetchingDynamicDiscountAssociated: false,
                getDynamicDiscountAssociatedSuccess: defaultState.getDynamicDiscountAssociatedSuccess,
                getDynamicDiscountAssociatedFail: action.payload,
            };
        case GET_BUYER_ACTIVE_INVOICES:
            return {
                ...state,
                isFetchingActiveInvoices: true
            };
        case GET_BUYER_ACTIVE_INVOICES_SUCCESS:
            return {
                ...state,
                activeInvoice: action.payload,
                computedCurrencyFilters: getCurrenciesFilters(action.payload.invoicesData),
                isFetchingActiveInvoices: false
            };
        case GET_BUYER_ACTIVE_INVOICES_FAIL:
            return {
                ...state,
                activeInvoice: null,
                isFetchingActiveInvoices: false,
            };

        case GET_BUYER_ACTIVE_INVOICES_SILENT:
            return {
                ...state,
                isFetchingActiveInvoicesSilent: true
            };
        case GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT:
            return {
                ...state,
                activeInvoice: action.payload,
                computedCurrencyFilters: getCurrenciesFilters(action.payload.invoicesData),
                isFetchingActiveInvoicesSilent: false
            };
        case GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT:
            return {
                ...state,
                activeInvoice: null,
                isFetchingActiveInvoicesSilent: false,
            };
        default:
            return state;
    }
}

import * as React from 'react';
import "./ScreenWrapper.scss";

export interface Props {
    title?: string
    description?: string
    children: React.ReactNode,
    summaryComponent?: React.ReactNode,
    button?: React.ReactNode,
    classModifierTitle?: any,
    styleDescription?: any
    formattedDescription?:boolean
}

const ScreenWrapper: React.FC<Props> = ({
                                            title,
                                            description,
                                            children,
                                            styleDescription,
                                            classModifierTitle,
                                            summaryComponent,
                                            button,
                                            formattedDescription
                                        }) => {
    return (
        <div className="screenWrapper">
            <div className="screenWrapper__containerTopSection">
                <div className="screenWrapper__titleSection" style={styleDescription}>
                    <div className="screenWrapper__containerTopSection">
                        {title &&
                        <div className={`screenWrapper__title ${classModifierTitle}`}>
                            {title}
                        </div>
                        }

                    </div>
                    {description && <div className={"screenWrapper__description "+(formattedDescription ? "screenWrapper__description--formattedText" : "")}>
                        {description}
                    </div>
                    }
                </div>
                <div className="screenWrapper__summaryComponentSection">
                    {button &&
                    <div className="screenWrapper__containerButton">
                        {button}
                    </div>
                    }
                    {summaryComponent}
                </div>
            </div>
            {children}
        </div>
    );
};

export default ScreenWrapper;

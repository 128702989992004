import * as React from 'react';
import "./Card.scss";
import {useTranslation} from "react-i18next";
import {ReactNode} from "react";

interface ObjectKeyValue {
    key: string,
    value: any,
    style?: any
}

interface Props {
    list: ObjectKeyValue[]
    description?: string,
    titleLabel?: string,
    titleValue?: string,
    info?: ReactNode,
    cardActions?: { name: string, handler: { (value: any): void } }[]
    labelShowInfo?: string,
    classNameItem?: string,
    style?: any,
    rightChildren?: ReactNode
    setInfoOpen?(): void;
    subTitle?: any
    warning?: any
}

const Card: React.FC<Props> = ({
                                   list,
                                   description,
                                   titleLabel,
                                   titleValue,
                                   info,
                                   setInfoOpen,
                                   labelShowInfo,
                                   style,
                                   rightChildren,
                                   classNameItem,
                                   subTitle,
                                   cardActions,
                                   warning
                               }) => {

    const {t} = useTranslation();

    return (
        <div className="shadowCard customCard">
            <div className="customCard__mainContainer">
                <div className="customCard__content" style={style}>
                    {titleValue &&
                    <>
                        <div className="customCard__containerTitle">
                            <div className="customCard__title">
                                {titleLabel} {titleValue}
                            </div>
                            {subTitle && <div className="customCard__rightSubTitle">{subTitle}</div>}
                        </div>
                        <div className="line"/>
                    </>
                    }
                    <div className="customCard__containerData">
                        <div className="customCard__leftSide">
                            <div className="customCard__list">
                                {list.map(({key, value, style = {}}: ObjectKeyValue, id) => {
                                    return (
                                        <div className={`customCard__item ${classNameItem}`} key={id}>
                                            <div className="listItemTitle" style={style.key}>{key}</div>
                                            <div
                                                className={"listItemValue " + style.valueMod || ""}
                                                style={style.value}
                                                title={typeof value === "string" ? value : ""}
                                            >
                                                {value}
                                            </div>
                                        </div>
                                    );
                                })}
                                {description &&
                                <div className="customCard__item">
                                    <div className="listItemTitle">
                                        {t("SCFProgram.modal.description")}
                                    </div>
                                    <div className="listItemValue" style={{paddingRight: "15px"}}>
                                        {description}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        {cardActions && <div className="customCard__rightSideContainer">
                            {cardActions.map?.(({name, handler}, index) =>
                                <div key={index} className="customCard__rightSide">
                                    <div className="customCard__rightSideContent">
                                        <div
                                            className="customCard__clickableText"
                                            onClick={handler}
                                        >
                                            {name}
                                        </div>
                                    </div>
                                </div>)}
                        </div>}
                        {rightChildren}
                    </div>
                    {warning && <div className="customCard__warning">{warning}</div>}
                    {setInfoOpen && <div className="line ine--withoutMargin"/>}
                </div>
                {setInfoOpen &&
                <div
                    className="customCard__clickableTextShowInfo"
                    onClick={setInfoOpen}>
                    {labelShowInfo ? labelShowInfo : t("dynamicDiscount.showInformation")}
                </div>
                }
            </div>
            {info}
        </div>
    );
};

export default Card;

export interface CreateDossierResponse {
  token?: string;
  documents?: [];
  redirectUrl?: string;
}

export interface ProcessStartResponse {
  processStatus: string;
  taskName: string;
  taskId: string;
  sessionKey: string;
}

export interface WebIdStartResponse {
  meetingWaitingTime: number;
  meetingStatus: string;
}

export interface WebIdQueueResponse {
  meetingStatus: string;
  meetingWaitingTime: number;
  meetingPlatform?: string;
  meetingUrl?: string;
  meetingId?: string;
}

export interface WebIdDossierResponse {
  process: {
    processStatus: string;
    taskName: string;
    taskId: string;
    sessionKey: string;
  };
  dossier: {
    status: string;
    person?: object;
    identified?: boolean;
    personalDataUpdated?: boolean;
    addressUpdated?: boolean;
    identityDocumentInfoUpdated?: boolean;
    contactInfoUpdated?: boolean;
  };
}

export interface UrlClauseDTO {
  url: string;
  name: string;
}

export interface ClauseDTO {
  id: string;
  text: string;
  group: string;
  optional: boolean;
  mode: string;
  urls?: UrlClauseDTO[];
}

export interface OneShotStartResponse {
  status: string;
  person: object;
  clauses: ClauseDTO[];
}

export interface AcceptedClauseDTO {
  id: string;
  value: boolean;
}

export interface OneShotRegisterRequest {
  clauses: AcceptedClauseDTO[];
}

export interface InfoCertSignature {
  id: string;
  text: string;
}

export interface InfoCertDocument {
  id: string;
  name: string;
  status: string;
  url: string;
  signatures: InfoCertSignature[];
  base64?: string;
}

export interface InfoCertDocumentDownload {
  name: string;
  base64: string;
  url?: string;
}

export interface OneShotRegisterResponse {
  status: string;
  documents: InfoCertDocument[];
}

export interface DocumentToSignDTO {
  id: string;
  clauses: AcceptedClauseDTO[];
}

export interface OneShotIssueSignRequest {
  pin: string;
  otp: string;
  documents: DocumentToSignDTO[];
}

export interface OneShotIssueSignResponse {
  process: ProcessStatusDTO;
  enrollment: EnrollmentStatusDTO;
}

export interface ProcessStatusDTO {
  processStatus: string;
}

export interface EnrollmentStatusDTO {
  status: string;
  documents: InfoCertDocumentDownload[];
}

export interface OneShotSendOTPResponse {
  status: string;
}

export interface RemoteSignStartResponse {
  status: string;
  documents: InfoCertDocument[];
}

export interface InfoCertLogoutResponse {
  status: string;
}

export interface InfoCertDeleteDossierResponse {
  status: string;
}

export interface UpdateDocumentSignStep {
  documentSignStep: string;
  token?: string;
}

export interface RemoteSignPrepareSignatureResponse {
  status: string;
}

export interface RemoteSignSignContractsRequest {
  pin: string;
  otp: string;
  documents: DocumentToSignDTO[];
}

export interface RemoteSignSignContractsResponse {
  process: ProcessStatusDTO;
  signatures: EnrollmentStatusDTO;
}

export interface RemoteSignSendOTPResponse {
  status: string;
}

export enum DocumentSignStep {
  START_DOCUMENT_SIGN = 'START_DOCUMENT_SIGN',
  DOCUMENT_SIGN_CLAUSES = 'DOCUMENT_SIGN_CLAUSES',
  DOCUMENT_SIGN_OTP = 'DOCUMENT_SIGN_OTP',
  DOCUMENT_SIGN_DOWNLOAD_DOCUMENT = 'DOCUMENT_SIGN_DOWNLOAD_DOCUMENT',
  END_DOCUMENT_SIGN = 'END_DOCUMENT_SIGN',
}

export enum ActionSigningStatusDTO {
  NO_SIGNED = 'NO_SIGNED',
  SIGNING = 'SIGNING',
  SIGNED = 'SIGNED',
}

export interface SignerDTO {
  name: string;
  status: ActionSigningStatusDTO;
}

export enum ActionTypes {
  PROGRAM = 'PROGRAM',
  CREDIT_LINE = 'CREDIT_LINE',
  CREDIT_LINE_PRICING = 'CREDIT_LINE_PRICING',
  CREDIT_CESSION = 'CREDIT_CESSION',
  DYNAMIC_DISCOUNT_CONFIRM = 'DYNAMIC_DISCOUNT_CONFIRM',
  DYNAMIC_DISCOUNT_LINE = 'DYNAMIC_DISCOUNT_LINE',
  PAYMENT_RECEIPT = 'PAYMENT_RECEIPT',
  DELEGATION = 'DELEGATION',
  ONBOARDING = 'ONBOARDING',
  BUYER_TARIFF = 'BUYER_TARIFF',
  MONEYLENDER_TARIFF = 'MONEYLENDER_TARIFF',
  DELEGATION_PROGRAM = 'DELEGATION_PROGRAM',
  DELEGATION_CREDIT_LINE = 'DELEGATION_CREDIT_LINE',
  DELEGATION_CREDIT_LINE_PRICING = 'DELEGATION_CREDIT_LINE_PRICING',
  DELEGATION_CREDIT_CESSION = 'DELEGATION_CREDIT_CESSION',
  DELEGATION_DYNAMIC_DISCOUNT_CONFIRM = 'DELEGATION_DYNAMIC_DISCOUNT_CONFIRM',
  DELEGATION_DYNAMIC_DISCOUNT_LINE = 'DELEGATION_DYNAMIC_DISCOUNT_LINE',
  DELEGATION_PAYMENT_RECEIPT = 'DELEGATION_PAYMENT_RECEIPT',
  DELETE_DELEGATION_PROGRAM = 'DELETE_DELEGATION_PROGRAM',
  DELETE_DELEGATION_CREDIT_LINE = 'DELETE_DELEGATION_CREDIT_LINE',
  DELETE_DELEGATION_CREDIT_LINE_PRICING = 'DELETE_DELEGATION_CREDIT_LINE_PRICING',
  DELETE_DELEGATION_CREDIT_CESSION = 'DELETE_DELEGATION_CREDIT_CESSION',
  DELETE_DELEGATION_DYNAMIC_DISCOUNT_CONFIRM = 'DELETE_DELEGATION_DYNAMIC_DISCOUNT_CONFIRM',
  DELETE_DELEGATION_DYNAMIC_DISCOUNT_LINE = 'DELETE_DELEGATION_DYNAMIC_DISCOUNT_LINE',
  DELETE_DELEGATION_PAYMENT_RECEIPT = 'DELETE_DELEGATION_PAYMENT_RECEIPT',
  BUYER_INCOME = 'BUYER_INCOME',
  DELEGATION_BUYER_INCOME = 'DELEGATION_BUYER_INCOME',
  DELETE_DELEGATION_BUYER_INCOME = 'DELETE_DELEGATION_BUYER_INCOME',
  BUYER_REPORT = 'BUYER_REPORT',
}

export interface DocumentDTO {
  documentId: string;
  name: string;
}

export interface ActionDTO {
  id: string;
  type: ActionTypes;
  createdBy: string;
  createDateEpoch: number;
  token: string;
  signers: SignerDTO[];
  documents: DocumentDTO[];
  marketplaceCode?: string;
  enabled: boolean;
  rational: string;
}

export interface InfocertStatusDTO {
  dossierStatus: InfocertProcessStatus;
  dossierType: InfocertDossierType;
}

export interface CombinationDTO {
  id: string;
  actionType: string;
  signer1: string;
  signer1Name: string;
  signer2: string;
  signer2Name: string;
  signer3: string;
  signer3Name: string;
  amount: number;
  currency: string;
}

export interface CombinationsRequest {
  referenceId: string;
  actionData: string;
}

export enum ActionCreationStatus {
  OK = 'OK', //can proceed with creation
  BLOCKED = 'BLOCKED', //request is not valid, already present other request
}

export interface CombinationsResponse {
  status: ActionCreationStatus;
  signersCombination: CombinationDTO[];
}

export interface SignDocumentRequestDTO {
  actionData: string;
}

export enum ActionSigningStatus {
  SIGNING = 'SIGNING',
  SIGNED = 'SIGNED',
}

export enum InfocertProcessStatus {
  START = 'START',
  IDENTIFIED = 'IDENTIFIED',
  REQUEST_CERTIFICATE = 'REQUEST_CERTIFICATE',
}

export enum InfocertDossierType {
  OPERATOR = 'OPERATOR',
  SPID = 'SPID',
  NONE = 'NONE',
}

export interface SignStatusRequest {
  signingStatus: ActionSigningStatus;
}

export interface ArchiveItemDTO {
  id: string;
  type: ActionTypes;
  createdBy: string;
  createDateEpoch: number;
  referenceId: string;
  signers: SignerDTO[];
}

export interface ConfigurationDTO {
  ActionRequest: ActionTypes;
  signer1: string;
  signer2: string;
  signer3: string;
  companyId: string;
}

import * as React from 'react';
import {useTranslation} from "react-i18next";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {AppState} from "../../redux/store";
import "./SummaryData.scss";
import {countryUtils} from "../../utils/countryUtils";
import {timeUtils} from "../../utils/timeUtils";

const getFields = (dataSet: any, t: any) => {

    const field = (field: string) => dataSet && dataSet[field] && dataSet[field].toUpperCase();
    const country = (field: string) => dataSet && dataSet[field] && countryUtils.fromCodeToCountryName(dataSet[field]).toUpperCase();
    const province = (field: string) => dataSet && dataSet[field] && countryUtils.fromCodeToProvinceName(dataSet[field]).toUpperCase();
    const date = (field: string) => dataSet && dataSet[field] && timeUtils.formatToLocaleDate(dataSet[field]);
    const question = (field: string) => (dataSet && dataSet[field] && typeof dataSet[field] === "boolean") && (dataSet[field] ? t("common.button.yes") : t("common.button.no"));

    const companyData = [
        {id: "businessName", key: "onBoarding.buyer.companyData.businessName", value: field("businessName")},
        {id: "companyRegistry", key: "onBoarding.buyer.companyData.registerOfCompanies", value: field("companyRegistry")},
        {id: "vatNumber", key: "onBoarding.buyer.companyData.vatNumber", value: field("vatNumber")},
        {id: "address", key: "onBoarding.buyer.companyData.address", value: field("address")},
        {id: "businessCountry", key: "onBoarding.buyer.companyData.nation", value: country("businessCountry")},
        {id: "city", key: "onBoarding.buyer.companyData.city", value: field("city")},
        {id: "province", key: "onBoarding.buyer.companyData.province", value: field("province")},
        {id: "zipCode", key: "onBoarding.buyer.companyData.cap", value: field("zipCode")},
        {id: "taxCode", key: "onBoarding.buyer.companyData.fiscalCode", value: field("taxCode")},
        {id: "email", key: "onBoarding.buyer.companyData.email", value: field("email")},
        {id: "pec", key: "onBoarding.buyer.companyData.pec", value: field("pec")},
        {id: "ateco", key: "onBoarding.buyer.companyData.atecoCode", value: field("ateco")},
        {id: "sdi", key: "onBoarding.buyer.companyData.SDI", value: field("sdi")},
        {id: "sapCode", key: "onBoarding.buyer.companyData.SAPCode", value: field("sapCode")},
        {id: "legalEntity", key: "onBoarding.buyer.companyData.legalEntity", value: field("legalEntity")},
    ];

    const extraCompanyData = [
        {id: "companyActivities", key: "onBoarding.company.extraCompanyData.companyActivities", value: field("companyActivities")},
        {id: "isClientTypeBuyer", key: "onBoarding.company.extraCompanyData.consumers", value: question("isClientTypeBuyer")},
        {id: "isClientTypeCompany", key: "onBoarding.company.extraCompanyData.company", value: question("isClientTypeCompany")},
        {id: "isClientTypePublic", key: "onBoarding.company.extraCompanyData.publicAdministrations", value: question("isClientTypePublic")},
        {id: "hasGambling", key: "onBoarding.company.extraCompanyData.hasGambling", value: question("hasGambling")},
        {id: "trustClass", key: "onBoarding.company.extraCompanyData.trustClass", value: field("trustClass")},
        {id: "noProfitSector", key: "onBoarding.company.extraCompanyData.noProfitSector", value: field("noProfitSector")},
    ];

    const personalData = [
        {id: "name", key: "onBoarding.buyer.personalData.name", value: field("name")},
        {id: "surname", key: "onBoarding.buyer.personalData.surname", value: field("surname")},
        {id: "sex", key: "onBoarding.buyer.personalData.sex", value: dataSet.sex && t("common.sex." + dataSet.sex).toUpperCase()},
        {id: "birthDate", key: "onBoarding.buyer.personalData.birthday", value: date("birthDate")},
        {id: "birthPlace", key: "onBoarding.buyer.personalData.birthPlace", value: field("birthPlace")},
        {id: "region", key: "onBoarding.buyer.personalData.region", value: field("region")},
        {id: "nationality", key: "onBoarding.buyer.personalData.nation", value: country("nationality")},
        {id: "cf", key: "onBoarding.buyer.personalData.cf", value: field("cf")},
        {id: "residenceCountry", key: "onBoarding.buyer.personalData.residenceCountry", value: country("residenceCountry")},
        {id: "residenceCity", key: "onBoarding.buyer.personalData.residenceCity", value: field("residenceCity")},
        {id: "residenceProvince", key: "onBoarding.buyer.personalData.residenceProvince", value: province("residenceProvince")},
        {id: "residenceZipCode", key: "onBoarding.buyer.personalData.residenceZipCode", value: field("residenceZipCode")},
        {id: "residenceAddress", key: "onBoarding.buyer.personalData.residenceAddress", value: field("residenceAddress")},
        {id: "residenceHouseNumber", key: "onBoarding.buyer.personalData.residenceHouseNumber", value: field("residenceHouseNumber")},
        {id: "startDuty", key: "onBoarding.company.definitionRole.startDuty", value: date("startDuty")},
        {id: "endDuty", key: "onBoarding.company.definitionRole.startDuty", value: date("endDuty")},
    ];

    const identityDocument = [
        {id: "documentType", key: "onBoarding.buyer.documentData.documentType", value: dataSet.documentType && t("onBoarding.buyer.documentData.document." + dataSet.documentType).toUpperCase()},
        {id: "documentNumber", key: "onBoarding.buyer.documentData.documentNumber", value: field("documentNumber")},
        {id: "documentEmitter", key: "onBoarding.buyer.documentData.documentEmitter", value: field("documentEmitter")},
        {id: "documentCityEmitter", key: "onBoarding.buyer.documentData.documentCityEmitter", value: field("documentCityEmitter")},
        {id: "documentProvinceEmitter", key: "onBoarding.buyer.documentData.documentProvinceEmitter", value: province("documentProvinceEmitter")},
        {id: "documentNationEmitter", key: "onBoarding.buyer.documentData.documentCountryEmitter", value: country("documentNationEmitter")},
        {id: "documentReleaseDate", key: "onBoarding.buyer.documentData.documentReleaseDate", value: date("documentReleaseDate")},
        {id: "documentExpiryDate", key: "onBoarding.buyer.documentData.documentExpiryDate", value: date("documentExpiryDate")},
    ];

    const questionnaire = [
        {id: "politicallyExposed", key: "onBoarding.buyer.questionnaire.politicallyExposed", value: question("politicallyExposed")},
        {id: "institutionalPosition", key: "onBoarding.buyer.questionnaire.institutionalPosition", value: dataSet.institutionalPosition && dataSet.institutionalPosition.toUpperCase()},
        {id: "institutionalPositionCountry", key: "onBoarding.buyer.questionnaire.institutionalPositionCountry", value: country("institutionalPositionCountry")},
        {id: "mainIncome", key: "onBoarding.buyer.questionnaire.mainIncome", value: field("mainIncome")},
        {id: "publicOfficeHeld", key: "onBoarding.buyer.questionnaire.publicOfficeHeldShorter", value: question("publicOfficeHeld")},
        {id: "officeHeld", key: "onBoarding.buyer.questionnaire.officeHeld", value: dataSet.officeHeld},
    ];

    const role = [
        {id: "role", key: "onBoarding.company.definitionRole.dropdown1", value: dataSet.role && t(`onBoarding.company.definitionRole.${dataSet.role}`).toUpperCase()},
        {id: "hasPowerToSign", key: "onBoarding.company.definitionRole.dropdown2", value: question("hasPowerToSign")},
    ];

    const programInfo = [
        {id: "programId", key: "SCFProgram.idSCFProgram", value: dataSet.programId},
        {id: "programDescription", key: "moneylender.modifyVendorRegistry.descProgram", value: dataSet.programDescription},
        {id: "vendorName", key: "moneylender.modifyVendorRegistry.buyer", value: dataSet.vendorName},
    ];

    return {companyData, extraCompanyData, personalData, identityDocument, questionnaire, role, programInfo};
};

const hasValues = (data: any) => data && !!data.find((item: any) => !!item.value);

const renderFields = (data: any, t: any) => {
    return <>
        {data.map((item: any, id: number) => {
            if (item.value) {
                return (
                    <div className="summaryData__cell" key={id}>
                        <div className="summaryData__cellTitle">
                            {t(item.key)}
                        </div>
                        <div className="summaryData__value">
                            {item.value}
                        </div>
                    </div>
                );
            } else return null;
        })}
    </>;
};

const renderUserSummary = ({companyData, extraCompanyData, personalData, identityDocument, questionnaire, role, programInfo}: any, t: any, uniteFields?: boolean) => {
    const allFields = uniteFields && [...companyData, ...extraCompanyData, ...personalData, ...identityDocument, ...questionnaire, ...role,...programInfo];
    return <>
        {uniteFields && hasValues(allFields) &&
        <div className="summaryData__cellContainer">
            {renderFields(allFields, t)}
        </div>}
        {!uniteFields && <>
            {(hasValues(companyData) || hasValues(extraCompanyData)) && <>
                <div className="summaryData__title">
                    {t("onBoarding.buyer.companyData.title")}
                </div>

                {/*COMPANY DATA*/}
                <div className="summaryData__cellContainer">
                    {renderFields(companyData, t)}
                </div>

                {/*EXTRA COMPANY DATA*/}
                <div className="summaryData__cellContainer">
                    {renderFields(extraCompanyData, t)}
                </div>
            </>}
            {/*PERSONAL DATA*/}
            {hasValues(personalData) && <>
                <div className="summaryData__title">
                    {t("onBoarding.buyer.personalData.title")}
                </div>
                <div className="summaryData__cellContainer">
                    {renderFields(personalData, t)}
                </div>
            </>}
            {/*DOCUMENT DATA*/}
            {hasValues(identityDocument) && <>
                <div className="summaryData__title">
                    {t("onBoarding.buyer.documentData.title")}
                </div>
                <div className="summaryData__cellContainer">
                    {renderFields(identityDocument, t)}
                </div>
            </>}
            {/*QUESTIONNAIRE*/}
            {hasValues(questionnaire) && <>
                <div className="summaryData__title">
                    {t("onBoarding.buyer.questionnaire.title")}
                </div>
                <div className="summaryData__cellContainer">
                    {renderFields(questionnaire, t)}
                </div>
            </>}
            {/*ROLES*/}
            {hasValues(role) && <>
                <div className="summaryData__title">
                    {t("onBoarding.company.definitionRole.title")}
                </div>
                <div className="summaryData__cellContainer">
                    {renderFields(role, t)}
                </div>
            </>}

            {/*PROGRAM_DATA*/}
            {hasValues(programInfo) && <>
                <div className="summaryData__title">
                    {t("onBoarding.company.definitionRole.title")}
                </div>
                <div className="summaryData__cellContainer">
                    {renderFields(programInfo, t)}
                </div>
            </>}
        </>}
    </>;
};

const SummaryData: React.FC<any> = ({
                                        onBoardingData,
                                        userDataToDisplay
                                    }) => {

    const {t} = useTranslation();

    let userData, legalRepresentativeData, ownersData;
    if (userDataToDisplay) {
        userData = getFields(userDataToDisplay, t);
    } else if (onBoardingData) {
        userData = getFields(onBoardingData, t);

        legalRepresentativeData = onBoardingData && !onBoardingData.legalRepresentative && onBoardingData.legalRepresentativeData && getFields(onBoardingData.legalRepresentativeData, t);
        ownersData = onBoardingData
            && onBoardingData.effectiveOwners
            && onBoardingData.effectiveOwners.length
            && onBoardingData.effectiveOwners
                .filter((ownerData: any) => ownerData.name && ownerData.mail)
                .map((ownerData: any) => getFields(ownerData, t));
    }

    return (
        <div className="summaryData__container">
            {!userDataToDisplay && userData && renderUserSummary(userData, t)}
            {!!userDataToDisplay && renderUserSummary(userData, t, true)}
            {!!legalRepresentativeData && <>
                <div className="summaryData__subTitle">
                    {`${t("onBoarding.company.legalRepresentative.title")}: ${onBoardingData.legalRepresentativeData.name} ${onBoardingData.legalRepresentativeData.surname}`}
                </div>
                <div className="summaryData__subSection">
                    {renderUserSummary(legalRepresentativeData, t)}
                </div>
            </>}
            {!!ownersData && ownersData.map((owner: any, index: any) =>
                <React.Fragment key={index}>
                    <div className="summaryData__subTitle">
                        {`${t("onBoarding.company.effectiveOwner.title")}: `}
                    </div>
                    <div className="summaryData__subSection">
                        {renderUserSummary(owner, t)}
                    </div>
                </React.Fragment>
            )}
        </div>);
};
const mapStateToProps = (store: AppState) => ({
    onBoardingData: store.auth.onBoardingData
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(SummaryData);


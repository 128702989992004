import * as React from 'react';
import Button from "../../Common/Button/Button";
import forward_arrow_right from "../../../assets/Icons/forward_arrow_right.svg";
import {useTranslation} from "react-i18next";
import "./NewUserCard.scss";
import tick from "../../../assets/Icons/tick.svg";

interface Props {
    title?: string,
    description?: string,
    icon?: any,
    styleCard?: string,
    disabled?: boolean,
    svgPath?: any,
    goToNextPage?(): void;
    body?: any
    onClick?: any
    iconElement?: any
    selected?: boolean
}

const NewUserCard: React.FC<Props> = ({
                                          title,
                                          icon,
                                          description,
                                          goToNextPage,
                                          styleCard,
                                          disabled,
                                          svgPath,
                                          body,
                                          onClick,
                                          iconElement,
                                          selected
                                      }) => {

    const {t} = useTranslation();
    const marginBottom = description ? "newUserCard__title--marginBottom5px" : "newUserCard__title--marginBottom20px";
    const grayColor = disabled && "newUserCard--gray";
    const iconColored = disabled ? "newUserCard--gray" : "newUserCard--colored";

    return (
        <div className={`newUserCard ${onClick ? "newUserCard--clickable" : ""}`} onClick={onClick}>
            <div className={`newUserCard__card ${styleCard}`}>
                <div className="newUserCard__content">
                    {icon &&
                    <img
                        className="newUserCard__image"
                        src={icon}
                        alt="individual"
                    />}
                    {selected && <img
                        className="newUserCard__selected"
                        src={tick}
                        alt="email_sent"
                        width="44px"
                    />}
                    {iconElement}
                    {svgPath && <div className={`newUserCard__image ${iconColored}`}> {svgPath} </div>}
                    <div className="newUserCard__titleContainer">
                        <div className={`newUserCard__title ${marginBottom} ${grayColor}`}>
                            {title}
                        </div>
                        {body}
                        {description && !disabled &&
                        <div className="newUserCard__description">
                            {description}
                        </div>
                        }
                    </div>
                    {goToNextPage &&
                    <Button
                        label={t("common.button.select")}
                        onClick={goToNextPage}
                        icon={forward_arrow_right}
                        width="140px"
                    />}

                </div>
            </div>
        </div>
    );
};

export default NewUserCard;

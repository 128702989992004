import {MyResponse} from "../model/ServiceDTO";
import {configService} from "./ConfigService";

const TIMEOUT_NO_CONNECTION_AVAILABLE = 20000;

export const DEFAULT_TENANT_ID = configService.getDefaultTenantId();

let currentCsrfToken: string = "";
let currentTenantId: string = DEFAULT_TENANT_ID || "";

export function setToken(token: string) {
    currentCsrfToken = token;
}

export function setTenantId(tenantId: string) {
    currentTenantId = tenantId;
}

function timeout<T>(ms: number, promise: Promise<MyResponse<T>>) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            reject("errors.networkTimeOut");
        }, ms);
        promise.then(resolve, reject);
    });
}

function fetchApi<T>(url: string, request: RequestInit, isExternal: boolean = false): Promise<MyResponse<T>> {
    if (!isExternal) {
        request.credentials = "include";
    }
    return fetch(url, request)
        .then(async response => {
            let myResponse = {} as MyResponse<T>;
            myResponse.status = response.status;
            try{
                const text = await response.text();
                try {
                    const json = JSON.parse(text);
                    myResponse.payload = json as T;
                } catch (e) {
                    myResponse.payload = (text || "") as unknown as T;
                }
            }
            catch (e) {
                console.log('Error reading response text: ', e);
            }
            return myResponse
        })
        .catch((e) => {
            let myResponse = {} as MyResponse<T>;
            myResponse.status = 500;
            myResponse.payload = {} as any;
            console.log('Fetch error: ', e);
            return myResponse;
        })
}

class NetworkService {

    get = async <T extends {}>(url: string, headers: object = [], isExternal: boolean = false, customTimeout?: number): Promise<MyResponse<T>> => {
        const request: any = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                ...headers,
            },
        };
        if (!isExternal) {
            request.headers['X-TenantID'] = currentTenantId
        }
        let response = await timeout<T>(customTimeout || TIMEOUT_NO_CONNECTION_AVAILABLE, fetchApi<T>(url, request, isExternal));
        console.log(`GET url: ${url}, response: `, response);
        return response as Promise<MyResponse<T>>;
    };

    post = async <T, K>(url: string, data: T, headers: object = [], isExternal: boolean = false, customTimeout?: number): Promise<MyResponse<K>> => {

        const stringData = JSON.stringify(data);
        const request: any = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: stringData,
        };
        if (!isExternal) {
            request.headers['X-CSRF-TOKEN'] = currentCsrfToken
            request.headers['X-TenantID'] = currentTenantId
        }
        const response = await timeout<K>(customTimeout || TIMEOUT_NO_CONNECTION_AVAILABLE, fetchApi<K>(url, request, isExternal));
        console.log(`POST url: ${url}, response: `, response);
        return response as Promise<MyResponse<K>>;
    };

    put = async <T, K>(url: string, data: T, headers: object = [], isExternal: boolean = false): Promise<MyResponse<K>> => {
        const stringData = JSON.stringify(data);
        const request : any = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: stringData
        };
        if (!isExternal) {
            request.headers['X-CSRF-TOKEN'] = currentCsrfToken
            request.headers['X-TenantID'] = currentTenantId
        }
        const response = await timeout<K>(TIMEOUT_NO_CONNECTION_AVAILABLE, fetchApi<K>(url, request, isExternal)) ;
        console.log(`PUT url: ${url}, response: `, response);
        return response as Promise<MyResponse<K>>;
    };
}

export const networkService = new NetworkService();

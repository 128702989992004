import {buyerService} from "../../service/BuyerService";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {handleResponse} from "../reduxUtils";
import {
    BuyerAction,
    GET_BUYERS_AMOUNT_INVOICES,
    GET_BUYERS_INVOICES,
    GET_BUYERS_INVOICES_DELAYED,
    GET_BUYERS_INVOICES_DELAYED_REQUEST,
    GET_BUYERS_INVOICES_FAIL,
    GET_BUYERS_INVOICES_REQUEST,
    GET_DYNAMIC_DISCOUNT_ASSOCIATED,
    GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL,
    GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS,
    GET_CURRENCIES,
    GET_CURRENCIES_FAIL,
    GET_CURRENCIES_REQUEST,
    GET_BUYER_ACTIVE_INVOICES_SILENT,
    GET_BUYER_ACTIVE_INVOICES,
    GET_BUYER_ACTIVE_INVOICES_SUCCESS,
    GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT,
    GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT,
    GET_BUYER_ACTIVE_INVOICES_FAIL
} from "./buyer-interface";
import {BuyerInvoicesSelectedMethod} from "../../model/BuyerDTO";

export const getInvoicesCurrencies = (): ThunkAction<void, {}, {}, BuyerAction> =>
    async (dispatch: ThunkDispatch<{}, {}, BuyerAction>): Promise<BuyerAction> => {
        dispatch({type: GET_CURRENCIES_REQUEST, payload: ""});
        const responsePromise = buyerService.getInvoiceCurrencies();
        return await handleResponse(dispatch, GET_CURRENCIES, GET_CURRENCIES_FAIL, responsePromise);
    };

export const getDynamicDiscountAssociated = (): ThunkAction<void, {}, {}, BuyerAction> =>
    async (dispatch: ThunkDispatch<{}, {}, BuyerAction>): Promise<BuyerAction> => {
        dispatch({type: GET_DYNAMIC_DISCOUNT_ASSOCIATED, payload: null});
        const responsePromise = buyerService.getDynamicDiscountAssociated();
        return await handleResponse(dispatch, GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS, GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL, responsePromise);
    };

export const getBuyerInvoices = (selectionMethod: BuyerInvoicesSelectedMethod, amount?: number, currency?: string): ThunkAction<void, {}, {}, BuyerAction> =>
    async (dispatch: ThunkDispatch<{}, {}, BuyerAction>): Promise<BuyerAction> => {
        dispatch({type: selectionMethod === BuyerInvoicesSelectedMethod.SELECTION_METHOD_NORMAL ? GET_BUYERS_INVOICES_REQUEST : GET_BUYERS_INVOICES_DELAYED_REQUEST, payload: ""});
        const responsePromise = buyerService.getInvoices(selectionMethod, amount, currency);
        const successType = (selectionMethod === BuyerInvoicesSelectedMethod.SELECTION_METHOD_AMOUNT && GET_BUYERS_AMOUNT_INVOICES)
            || (selectionMethod === BuyerInvoicesSelectedMethod.SELECTION_METHOD_DELAYED && GET_BUYERS_INVOICES_DELAYED)
            || GET_BUYERS_INVOICES;
        return await handleResponse(dispatch, successType, GET_BUYERS_INVOICES_FAIL, responsePromise);
    };

export const getActiveBuyerInvoices = (isSilent = false): ThunkAction<void, {}, {}, BuyerAction> =>
    async (dispatch: ThunkDispatch<{}, {}, BuyerAction>) => {
        dispatch({type: isSilent ? GET_BUYER_ACTIVE_INVOICES_SILENT : GET_BUYER_ACTIVE_INVOICES, payload: null});
        const responsePromise = buyerService.getBuyerActiveInvoices();
        return await handleResponse(dispatch, isSilent ? GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT : GET_BUYER_ACTIVE_INVOICES_SUCCESS, isSilent ? GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT : GET_BUYER_ACTIVE_INVOICES_FAIL, responsePromise);
    };

import * as React from 'react';
import ScreenWrapper from "../../../components/AdminComponent/ScreenWrapper/ScreenWrapper";
import {useTranslation} from "react-i18next";
import "../../../components/Common/Table/Table.scss";
import SummaryData from "../../../components/SummaryComponent/SummaryData";
import CustomUploadFile from "../../../components/Common/CustomUploadFile/CustomUploadFile";
import "./UserCompanySummary.scss";
import {InfoCertDocumentDownload} from "../../../model/InfoCertDTO";
import DownloadPDF from "../../../components/Common/DownloadPDF/DownloadPDF";
import DisplayLegalFiles from "../../common/uploadLegalFiles/DisplayLegalFiles";
import {AppState} from "../../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {LegalFilesDTO} from "../../../model/UserDTO";
import {deleteLegalFile, getLegalFiles, updateLegalFile} from "../../../redux/auth/auth-actions";
import {connect} from "react-redux";

export interface props {
    userData: any,
    title?: any
    summary?: any
    [index: string]: any
}

const UserCompanySummary: React.FC<props> = ({
                                                 title,
                                                 summary,
                                                 buttons,
                                                 userData,
                                                 updateLegalFileError,
                                                 updateLegalFile,
                                                 deleteLegalFile,
                                                 setIsUploading,
                                                 getLegalFiles,
                                                 getLegalFileResponse,
                                                 companyId,
                                                 noLegalFilesEdit
                                             }) => {

    const {t} = useTranslation();
    const [error, setError] = React.useState("");
    const [legalFilesLocal, setlLegalFilesLocal] = React.useState([]);

    const hasValues = (data: any) => data && Object.entries(data).find(element => !!element[1]);
    const {user, userFiles, legalRepresentative, legalRepresentativeFiles, companyData, documents, effectiveOwner1, effectiveOwnerFiles1, effectiveOwner2, effectiveOwnerFiles2, effectiveOwner3, effectiveOwnerFiles3, effectiveOwner4, effectiveOwnerFiles4, currentEffectiveOwnerFiles, currentEffectiveOwner} = userData;

    React.useEffect(() => {
        if (companyId) getLegalFiles?.(companyId);
    }, [getLegalFiles, companyId]);

    React.useEffect(() => {
        setError(updateLegalFileError);
    }, [updateLegalFileError]);

    React.useEffect(() => {
        getLegalFileResponse && setlLegalFilesLocal(getLegalFileResponse);
    }, [getLegalFileResponse]);

    React.useEffect(() => {
        userData?.legalFiles && setlLegalFilesLocal(userData.legalFiles);
    }, [userData]);


    const uploadPdf = async (pdfValues: LegalFilesDTO) => {
        const success = await updateLegalFile(pdfValues, companyId);
        if (success) {
            if (companyId) getLegalFiles?.(companyId);
        }
        return success;
    };

    const removeDocument = async (obj: LegalFilesDTO) => {
        const success = await deleteLegalFile(obj);
        if (success) {
            if (companyId) getLegalFiles?.(companyId);
            setError("");
        }
    };
    return (
        <ScreenWrapper
            title={title || t("admin.pendingRequest.title")}
            description={summary}
        >

            <div className="shadowCard " style={{paddingBottom: "20px", marginBottom: "30px"}}>
                <div className="cardContainerWithButtons__contentData">
                    {hasValues(companyData) &&
                    <>
                        <div className="summaryData__subTitle">
                            {t("onBoarding.companyData")}
                        </div>
                        <SummaryData userDataToDisplay={companyData}/>
                    </>
                    }
                    {hasValues(user) &&
                    <>
                        <div className="summaryData__subTitle">
                            {t("onBoarding.personalData")}
                        </div>
                        <SummaryData userDataToDisplay={user}/>
                    </>
                    }
                    {hasValues(userFiles) &&
                    <div className="userCompanySummary__filesContainer">
                        {userFiles.frontIdScan &&
                        <CustomUploadFile
                            fileData={userFiles.frontIdScan}
                            title={t("admin.pendingRequest.documentIDFront")}
                        />}
                        {userFiles.backIdScan &&
                        <CustomUploadFile
                            fileData={userFiles.backIdScan}
                            title={t("admin.pendingRequest.documentIDBack")}
                        />}
                        {userFiles.frontFiscalCodeScan &&
                        <CustomUploadFile
                            fileData={userFiles.frontFiscalCodeScan}
                            title={t("admin.pendingRequest.fiscalCodeFront")}
                        />}
                        {userFiles.backFiscalCodeScan &&
                        <CustomUploadFile
                            fileData={userFiles.backFiscalCodeScan}
                            title={t("admin.pendingRequest.fiscalCodeBack")}
                        />
                        }
                    </div>
                    }
                    {hasValues(legalRepresentative) &&
                    <>
                        <div className="summaryData__subTitle">
                            {t("onBoarding.company.legalRepresentative.title")}
                        </div>
                        <SummaryData userDataToDisplay={legalRepresentative}/>
                    </>
                    }
                    {hasValues(legalRepresentativeFiles) &&
                    <div className="userCompanySummary__filesContainer">
                        {legalRepresentativeFiles.frontIdScan &&
                        <CustomUploadFile
                            fileData={legalRepresentativeFiles.frontIdScan}
                            title={t("admin.pendingRequest.documentIDFront")}
                        />}
                        {legalRepresentativeFiles.backIdScan &&
                        <CustomUploadFile
                            fileData={legalRepresentativeFiles.backIdScan}
                            title={t("admin.pendingRequest.documentIDBack")}
                        />}
                        {legalRepresentativeFiles.frontFiscalCodeScan &&
                        <CustomUploadFile
                            fileData={legalRepresentativeFiles.frontFiscalCodeScan}
                            title={t("admin.pendingRequest.fiscalCodeFront")}
                        />}
                        {legalRepresentativeFiles.backFiscalCodeScan &&
                        <CustomUploadFile
                            fileData={legalRepresentativeFiles.backFiscalCodeScan}
                            title={t("admin.pendingRequest.fiscalCodeBack")}
                        />}
                    </div>
                    }
                    {hasValues(effectiveOwner1) &&
                    <>
                        <div className="summaryData__subTitle">
                            {hasValues(currentEffectiveOwner) ? t("onBoarding.company.effectiveOwner.titleNew") : (t("onBoarding.company.effectiveOwner.title") + " 1")}
                        </div>
                        <SummaryData userDataToDisplay={effectiveOwner1}/>
                    </>
                    }
                    {hasValues(effectiveOwnerFiles1) &&
                    <div className="userCompanySummary__filesContainer">
                        {effectiveOwnerFiles1.frontIdScan &&
                        <CustomUploadFile
                            fileData={effectiveOwnerFiles1.frontIdScan}
                            title={t("admin.pendingRequest.documentIDFront")}
                        />
                        }
                        {effectiveOwnerFiles1.backIdScan &&
                        <CustomUploadFile
                            fileData={effectiveOwnerFiles1.backIdScan}
                            title={t("admin.pendingRequest.documentIDBack")}
                        />
                        }
                        {effectiveOwnerFiles1.frontFiscalCodeScan &&
                        <CustomUploadFile
                            fileData={effectiveOwnerFiles1.frontFiscalCodeScan}
                            title={t("admin.pendingRequest.fiscalCodeFront")}
                        />
                        }
                        {effectiveOwnerFiles1.backFiscalCodeScan &&
                        <CustomUploadFile
                            fileData={effectiveOwnerFiles1.backFiscalCodeScan}
                            title={t("admin.pendingRequest.fiscalCodeBack")}
                        />
                        }
                    </div>
                    }
                    {hasValues(effectiveOwner2) &&
                        <>
                            <div className="summaryData__subTitle">
                                {hasValues(currentEffectiveOwner) ? t("onBoarding.company.effectiveOwner.titleNew") : (t("onBoarding.company.effectiveOwner.title") + " 2")}
                            </div>
                            <SummaryData userDataToDisplay={effectiveOwner2}/>
                        </>
                    }
                    {hasValues(effectiveOwnerFiles2) &&
                        <div className="userCompanySummary__filesContainer">
                            {effectiveOwnerFiles2.frontIdScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles2.frontIdScan}
                                    title={t("admin.pendingRequest.documentIDFront")}
                                />
                            }
                            {effectiveOwnerFiles2.backIdScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles2.backIdScan}
                                    title={t("admin.pendingRequest.documentIDBack")}
                                />
                            }
                            {effectiveOwnerFiles2.frontFiscalCodeScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles2.frontFiscalCodeScan}
                                    title={t("admin.pendingRequest.fiscalCodeFront")}
                                />
                            }
                            {effectiveOwnerFiles2.backFiscalCodeScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles2.backFiscalCodeScan}
                                    title={t("admin.pendingRequest.fiscalCodeBack")}
                                />
                            }
                        </div>
                    }
                    {hasValues(effectiveOwner3) &&
                        <>
                            <div className="summaryData__subTitle">
                                {hasValues(currentEffectiveOwner) ? t("onBoarding.company.effectiveOwner.titleNew") : (t("onBoarding.company.effectiveOwner.title") + " 3")}
                            </div>
                            <SummaryData userDataToDisplay={effectiveOwner3}/>
                        </>
                    }
                    {hasValues(effectiveOwnerFiles3) &&
                        <div className="userCompanySummary__filesContainer">
                            {effectiveOwnerFiles3.frontIdScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles3.frontIdScan}
                                    title={t("admin.pendingRequest.documentIDFront")}
                                />
                            }
                            {effectiveOwnerFiles3.backIdScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles3.backIdScan}
                                    title={t("admin.pendingRequest.documentIDBack")}
                                />
                            }
                            {effectiveOwnerFiles3.frontFiscalCodeScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles3.frontFiscalCodeScan}
                                    title={t("admin.pendingRequest.fiscalCodeFront")}
                                />
                            }
                            {effectiveOwnerFiles3.backFiscalCodeScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles3.backFiscalCodeScan}
                                    title={t("admin.pendingRequest.fiscalCodeBack")}
                                />
                            }
                        </div>
                    }
                    {hasValues(effectiveOwner4) &&
                        <>
                            <div className="summaryData__subTitle">
                                {hasValues(currentEffectiveOwner) ? t("onBoarding.company.effectiveOwner.titleNew") : ( t("onBoarding.company.effectiveOwner.title") + " 4")}
                            </div>
                            <SummaryData userDataToDisplay={effectiveOwner4}/>
                        </>
                    }
                    {hasValues(effectiveOwnerFiles4) &&
                        <div className="userCompanySummary__filesContainer">
                            {effectiveOwnerFiles4.frontIdScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles4.frontIdScan}
                                    title={t("admin.pendingRequest.documentIDFront")}
                                />
                            }
                            {effectiveOwnerFiles4.backIdScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles4.backIdScan}
                                    title={t("admin.pendingRequest.documentIDBack")}
                                />
                            }
                            {effectiveOwnerFiles4.frontFiscalCodeScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles4.frontFiscalCodeScan}
                                    title={t("admin.pendingRequest.fiscalCodeFront")}
                                />
                            }
                            {effectiveOwnerFiles4.backFiscalCodeScan &&
                                <CustomUploadFile
                                    fileData={effectiveOwnerFiles4.backFiscalCodeScan}
                                    title={t("admin.pendingRequest.fiscalCodeBack")}
                                />
                            }
                        </div>
                    }
                    {hasValues(currentEffectiveOwner) &&
                    <>
                        <div className="summaryData__subTitle">
                            {t("onBoarding.company.effectiveOwner.titleCurrent")}
                        </div>
                        <SummaryData userDataToDisplay={currentEffectiveOwner}/>
                    </>
                    }
                    {hasValues(currentEffectiveOwnerFiles) &&
                    <div className="userCompanySummary__filesContainer">
                        {currentEffectiveOwner.frontIdScan &&
                        <CustomUploadFile
                            fileData={currentEffectiveOwner.frontIdScan}
                            title={t("admin.pendingRequest.documentIDFront")}
                        />
                        }
                        {currentEffectiveOwner.backIdScan &&
                        <CustomUploadFile
                            fileData={currentEffectiveOwner.backIdScan}
                            title={t("admin.pendingRequest.documentIDBack")}
                        />
                        }
                        {currentEffectiveOwner.frontFiscalCodeScan &&
                        <CustomUploadFile
                            fileData={currentEffectiveOwner.frontFiscalCodeScan}
                            title={t("admin.pendingRequest.fiscalCodeFront")}
                        />
                        }
                        {currentEffectiveOwner.backFiscalCodeScan &&
                        <CustomUploadFile
                            fileData={currentEffectiveOwner.backFiscalCodeScan}
                            title={t("admin.pendingRequest.fiscalCodeBack")}
                        />
                        }
                    </div>
                    }
                    {hasValues(documents) &&
                    <>
                        <div className="summaryData__subTitle">
                            {t("admin.pendingRequest.downloadPDFDocuments")}
                        </div>

                        <div className="userCompanySummary__containerDownloadPDF">
                            {documents && documents.map((document: InfoCertDocumentDownload, index: number) => {
                                return (
                                    <div key={index} className="userCompanySummary__downloadPDF">
                                        <DownloadPDF fileData={document}/>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                    }
                    <DisplayLegalFiles
                        companyFiles={legalFilesLocal}
                        error={error}
                        setError={setError}
                        removeDocument={removeDocument}
                        uploadPdf={uploadPdf}
                        setIsUploading={setIsUploading}
                        noEdit={noLegalFilesEdit}
                    />
                </div>
            </div>
            <div className="cardContainerWithButtons__content">
                <div className="containerButtons">
                    {buttons}
                </div>
            </div>
        </ScreenWrapper>

    );
};
const mapStateToProps = (store: AppState) => {
    return {
        updateLegalFileError: store.auth.updateLegalFileError,
        getLegalFileResponse: store.auth.getLegalFileResponse
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateLegalFile: (payload: LegalFilesDTO, vat?: string) => dispatch(updateLegalFile(payload, vat)),
        getLegalFiles: (vat?: string) => dispatch(getLegalFiles(vat)),
        deleteLegalFile: (payload: LegalFilesDTO) => dispatch(deleteLegalFile(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCompanySummary);



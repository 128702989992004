import {PaymentStatus} from "./VendorDTO";

export interface BuyerInvoiceDTO {
    invoiceId: string[]
    documentId: string
    id: number[]
    currency: string
    expiryDate: Date
    delayExpiryDate: Date
    effectiveExpireDate: Date
    creditor?: string
    amount: number
    payableAmount: number
    selected: boolean
    delayRate: number
    creditNote?: boolean
}

export enum BuyerInvoicesSelectedMethod {
    SELECTION_METHOD_NORMAL = "NORMAL",
    SELECTION_METHOD_DELAYED = "DELAYED",
    SELECTION_METHOD_AMOUNT = "AMOUNT",
}

export interface BuyerInvoicesRequest {
    currency?: string
    selectedMethod?: BuyerInvoicesSelectedMethod
    amount?: number
}

export interface DynamicDiscountAssociatedDTO {
    type: string
    id: string
    amount: number
    originalAmount: number
    currency: string
}


export interface BuyerActiveInvoiceDTO {
    documentId: string
    id: string[]
    expiryDate: string
    effectiveExpireDate: string
    currency: string
    salable: boolean
    amount: number
    payableAmount: number
    currentCreditor?: string
    status: PaymentStatus
}

import {BuyerActiveInvoiceDTO, BuyerInvoiceDTO, DynamicDiscountAssociatedDTO} from "../../model/BuyerDTO";
export const GET_CURRENCIES = 'getInvoicesCurrencies';
export const GET_CURRENCIES_REQUEST = 'getInvoicesCurrenciesRequest';
export const GET_CURRENCIES_FAIL = 'getInvoicesCurrenciesFail';
export const GET_BUYERS_INVOICES = 'getInvoicesBuyer';
export const GET_BUYERS_INVOICES_DELAYED = 'getInvoicesDelayedBuyer';
export const GET_BUYERS_INVOICES_DELAYED_REQUEST = 'getInvoicesDelayedBuyerRequest';
export const GET_BUYERS_AMOUNT_INVOICES = 'getAmountInvoicesBuyer';
export const GET_BUYERS_AMOUNT_INVOICES_REQUEST = 'getAmountInvoicesBuyerRequest';
export const GET_BUYERS_INVOICES_REQUEST = 'getInvoicesBuyerRequest';
export const GET_BUYERS_INVOICES_FAIL = 'getInvoicesBuyerFail';
export const GET_DYNAMIC_DISCOUNT_ASSOCIATED = 'getDynamicDiscountAssociated';
export const GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS = 'getDynamicDiscountAssociatedSuccess';
export const GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL = 'getDynamicDiscountAssociatedFail';
export const GET_BUYER_ACTIVE_INVOICES = 'buyerActiveInvoices';
export const GET_BUYER_ACTIVE_INVOICES_SUCCESS = 'getBuyerActiveInvoicesSuccess';
export const GET_BUYER_ACTIVE_INVOICES_FAIL = 'getBuyerActiveInvoicesFailed';
export const GET_BUYER_ACTIVE_INVOICES_SILENT = 'getBuyerActiveInvoicesActionSilent';
export const GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT = 'getBuyerActiveInvoicesSuccessActionSilent';
export const GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT = 'getBuyerActiveInvoicesFailActionSilent';

export interface BuyerState {
    invoicesCurrencies: string[]
    invoicesCurrenciesIsFetching: boolean
    invoicesCurrenciesFail: any
    invoices: BuyerInvoiceDTO[] | null
    invoicesSelected: BuyerInvoiceDTO[] | null
    invoicesDelayed: BuyerInvoiceDTO[] | null
    computedInvoicesCurrencies: string[]
    invoicesIsFetching: boolean | null
    invoicesFail: any
    isFetchingDynamicDiscountAssociated: any
    getDynamicDiscountAssociatedSuccess: DynamicDiscountAssociatedDTO | null
    getDynamicDiscountAssociatedFail: any
    activeInvoice:BuyerActiveInvoiceDTO[] | null
    isFetchingActiveInvoices: boolean
    isFetchingActiveInvoicesSilent: boolean
    computedCurrencyFilters: string[]
}

export interface getInvoiceCurrencies {
    type: typeof GET_CURRENCIES | typeof GET_CURRENCIES_REQUEST | typeof GET_CURRENCIES_FAIL | typeof GET_BUYERS_INVOICES_DELAYED_REQUEST | typeof GET_BUYERS_AMOUNT_INVOICES_REQUEST
    payload: string[] | boolean | any
}

export interface getInvoices {
    type: typeof GET_BUYERS_INVOICES | typeof GET_BUYERS_INVOICES_REQUEST | typeof GET_BUYERS_INVOICES_FAIL | typeof GET_BUYERS_AMOUNT_INVOICES | typeof GET_BUYERS_INVOICES_DELAYED
    payload: BuyerInvoiceDTO[] | boolean | any
}

export interface getDynamicDiscountAssociated {
    type: typeof GET_DYNAMIC_DISCOUNT_ASSOCIATED
    payload: boolean | null
}

export interface getDynamicDiscountAssociatedSuccess {
    type: typeof GET_DYNAMIC_DISCOUNT_ASSOCIATED_SUCCESS
    payload: DynamicDiscountAssociatedDTO | null

}

export interface getDynamicDiscountAssociatedFail {
    type: typeof GET_DYNAMIC_DISCOUNT_ASSOCIATED_FAIL
    payload: any
}

export interface getBuyerActiveInvoicesAction {
    type: typeof GET_BUYER_ACTIVE_INVOICES
    payload: null
}

export interface getBuyerActiveInvoicesSuccessAction {
    type: typeof GET_BUYER_ACTIVE_INVOICES_SUCCESS
    payload: any
}

export interface getBuyerActiveInvoicesFailAction {
    type: typeof GET_BUYER_ACTIVE_INVOICES_FAIL
    payload: string
}

export interface getBuyerActiveInvoicesActionSilent {
    type: typeof GET_BUYER_ACTIVE_INVOICES_SILENT
    payload: null
}

export interface getBuyerActiveInvoicesSuccessActionSilent {
    type: typeof GET_BUYER_ACTIVE_INVOICES_SUCCESS_SILENT
    payload: any
}

export interface getBuyerActiveInvoicesFailActionSilent {
    type: typeof GET_BUYER_ACTIVE_INVOICES_FAIL_SILENT
    payload: string
}


export type BuyerAction =
    getInvoiceCurrencies |
    getInvoices |
    getDynamicDiscountAssociated |
    getDynamicDiscountAssociatedSuccess |
    getDynamicDiscountAssociatedFail |
    getBuyerActiveInvoicesAction |
    getBuyerActiveInvoicesSuccessAction |
    getBuyerActiveInvoicesFailAction |
    getBuyerActiveInvoicesActionSilent |
    getBuyerActiveInvoicesSuccessActionSilent |
    getBuyerActiveInvoicesFailActionSilent


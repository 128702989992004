import { AdminAction, AdminState } from './admin-interface';
import {
  AdminInvoiceDTO,
  BlockedUserDTO,
  InviteCustomerDTOResponse,
  OnBoardingStatusDTO,
  SapErrorDTO,
  StockCreditLine,
} from '../../model/AdminDTO';
import { admin_archive_step } from '../../screens/admin/AdminArchive/AdminArchive';
import { CashImprovementTariff, TariffDTO } from '../../model/CompanyDTO';
import { toastUtils } from '../../utils/toastUtils';
import i18n from 'i18next';
import { onlyUniqueElements } from '../../utils/commonUtils';

export const INVITE_CUSTOMER_REQUEST = 'inviteCustomer';
export const INVITE_CUSTOMER_FAIL = 'inviteCustomerFail';
export const INVITE_CUSTOMER_SUCCESS = 'inviteCustomerSuccess';
export const NEW_USER_STEP = 'newUserStep';
export const COMPANY_INFO_STEP = 'COMPANY_INFO_STEP';
export const SET_ROLE = 'setRole';
export const GET_BLOCKED_USERS = 'getBlockedUsers';
export const GET_BLOCKED_USERS_SUCCESS = 'getBlockedUsersSuccess';
export const GET_BLOCKED_USERS_FAIL = 'getBlockedUsersFail';
export const UNBLOCK_PENDING = 'getBlockedUsers';
export const UNBLOCK_PENDING_SUCCESS = 'getBlockedUsersSuccess';
export const UNBLOCK_PENDING_FAIL = 'getBlockedUsersFail';
export const REFUSE_PENDING = 'refuseUsers';
export const REFUSE_PENDING_SUCCESS = 'refuseUsersSuccess';
export const REFUSE_PENDING_FAIL = 'refuseUsersFail';
export const GET_SIGNERS = 'getSigners';
export const GET_SIGNERS_SUCCESS = 'getSignersSuccess';
export const GET_SIGNERS_FAIL = 'getSignersFail';
export const TARIFF_REQUEST = 'moneylenderTariffRequest';
export const TARIFF_FAIL = 'moneylenderTariffFail';
export const TARIFF_SUCCESS = 'moneylenderTariffSuccess';
export const CASH_IMPROVEMENT_TARIFF_REQUEST =
  'moneylenderCashImprovementTariffRequest';
export const CASH_IMPROVEMENT_TARIFF_FAIL =
  'moneylenderCashImprovementTariffFail';
export const CASH_IMPROVEMENT_TARIFF_SUCCESS =
  'moneylenderCashImprovementTariffSuccess';
export const SET_TARIFF_REQUEST = 'setTariffRequest';
export const SET_TARIFF_FAIL = 'setTariffFail';
export const SET_TARIFF_SUCCESS = 'setTariffSuccess';
export const UPDATE_TARIFF_REQUEST = 'updateTariffRequest';
export const UPDATE_TARIFF_FAIL = 'updateTariffFail';
export const UPDATE_TARIFF_SUCCESS = 'updateTariffSuccess';
export const GET_CUSTOMERS = 'getCustomers';
export const GET_CUSTOMERS_SUCCESS = 'getCustomersSuccess';
export const GET_CUSTOMERS_FAIL = 'getCustomersFail';
export const GET_ONBOARDING_STATUS = 'getOnboardingStatus';
export const GET_ONBOARDING_STATUS_SUCCESS = 'getOnboardingStatusSuccess';
export const GET_ONBOARDING_STATUS_FAIL = 'getOnboardingStatusFail';
export const GET_USERS_REQUEST = 'getUsersRequest';
export const GET_USERS_SUCCESS = 'getUsersSuccess';
export const GET_USERS_FAIL = 'getUsersFail';
export const BLOCK_USER_REQUEST = 'blockUsersRequest';
export const BLOCK_USER_SUCCESS = 'blockUsersSuccess';
export const BLOCK_USER_FAIL = 'blockUsersFail';
export const UNBLOCK_USER_REQUEST = 'unblockUsersRequest';
export const UNBLOCK_USER_SUCCESS = 'unblockUsersSuccess';
export const UNBLOCK_USER_FAIL = 'unblockUsersFail';
export const GET_COMPANIES_REQUEST = 'getCompaniesRequest';
export const GET_COMPANIES_SUCCESS = 'getCompaniesSuccess';
export const GET_COMPANIES_FAIL = 'getCompaniesFail';
export const BLOCK_COMPANIES_REQUEST = 'blockCompaniesRequest';
export const BLOCK_COMPANIES_SUCCESS = 'blockCompaniesSuccess';
export const BLOCK_COMPANIES_FAIL = 'blockCompaniesFail';
export const UNBLOCK_COMPANIES_REQUEST = 'unblockCompaniesRequest';
export const UNBLOCK_COMPANIES_SUCCESS = 'unblockCompaniesSuccess';
export const UNBLOCK_COMPANIES_FAIL = 'unblockCompaniesFail';
export const SET_ADMIN_ACTION_DISPATCHER = 'setAdminActionDispatcher';
export const STOCK_CREDIT_LINES_REQUEST = 'stock_credit_lines_request';
export const STOCK_CREDIT_LINES_SUCCESS = 'stock_credit_lines_success';
export const STOCK_CREDIT_LINES_FAIL = 'stock_credit_lines_fail';
export const VENDORS_WITHOUT_RELATIONS = 'vendorsWithout_Relations';
export const VENDORS_WITHOUT_RELATIONS_SUCCESS =
  'vendorsWithoutRelationsSuccess';
export const VENDORS_WITHOUT_RELATIONS_FAIL = 'vendorsWithoutRelationsFail';
export const CLEAR_TARIFF_ERROR = 'clearTariffError';
export const GET_SAP_ERROR = 'getSapError';
export const GET_SAP_ERROR_SUCCESS = 'sapErrorSuccess';
export const GET_SAP_ERROR_FAIL = 'sapErrorFail';
export const RETRY_SAP_ERROR = 'retrySapError';
export const RETRY_SAP_ERROR_SUCCESS = 'retrySapErrorSuccess';
export const RETRY_SAP_ERROR_FAIL = 'retrySapErrorFail';
export const DELETE_SAP_ERROR = 'retrySapError';
export const DELETE_SAP_ERROR_SUCCESS = 'retrySapErrorSuccess';
export const DELETE_SAP_ERROR_FAIL = 'retrySapErrorFail';
export const SET_LAST_DATE_SAP_ARRIVAL = 'setLastDateSapArrival';
export const SET_LAST_DATE_SAP_ARRIVAL_HIGHLIGHT =
  'setLastDateSapArrivalHighlight';
export const GET_SAP_ERROR_SUCCESS_SILENT = 'getSapErrorsSilent';
export const GET_ADMIN_INVOICES = 'adminInvoices';
export const GET_ADMIN_INVOICES_SUCCESS = 'getAdminInvoicesSuccess';
export const GET_ADMIN_INVOICES_FAIL = 'getAdminInvoicesFailed';
export const GET_ADMIN_INVOICES_SILENT = 'getAdminInvoicesActionSilent';
export const GET_ADMIN_INVOICES_SUCCESS_SILENT =
  'getAdminInvoicesSuccessActionSilent';
export const GET_ADMIN_INVOICES_FAIL_SILENT =
  'getAdminInvoicesFailActionSilent';

const defaultState: AdminState = {
  isFetchingInviteCustomer: false,
  inviteCustomerResponse: {} as InviteCustomerDTOResponse,
  inviteCustomerError: '',
  newUserStep: null,
  companyInfoStep: null,
  role: '',
  isFetchingGetBlockedUsers: false,
  getBlockedUsersResponse: [],
  getBlockedUsersFail: [],
  isFetchingUnblockPending: false,
  unblockPendingResponse: '',
  unblockPendingFail: '',
  isFetchingRefusePending: false,
  refusePendingResponse: '',
  refusePendingFail: '',
  isFetchingGetSigners: null,
  getSignersSuccess: '',
  getSignersFail: '',
  tariffIsFetching: false,
  tariffResponse: [] as TariffDTO[],
  tariffError: '',
  updateTariffIsFetching: false,
  updateTariffResponse: [] as any,
  updateTariffError: '',
  cashImprovementTariffIsFetching: false,
  cashImprovementTariffResponse: [] as CashImprovementTariff[],
  cashImprovementTariffError: '',
  setTariffIsFetching: false,
  setTariffResponse: [] as TariffDTO[],
  setTariffError: '',
  isFetchingGetCustomers: null,
  getCustomersSuccess: null,
  getCustomersFail: '',
  isFetchingGetOnboardingStatus: null,
  getOnboardingStatusSuccess: [],
  getOnboardingStatusFail: null,
  getUsersIsFetching: null,
  getUsersSuccess: null,
  getUsersFail: '',
  blockUsersIsFetching: null,
  blockUsersSuccess: null,
  blockUsersFail: '',
  unblockUserIsFetching: null,
  unblockUserSuccess: null,
  unblockUserFail: '',
  getCompaniesIsFetching: null,
  getCompaniesSuccess: null,
  getCompaniesFail: '',
  blockCompaniesIsFetching: null,
  blockCompaniesSuccess: null,
  blockCompaniesFail: '',
  unblockCompaniesIsFetching: null,
  unblockCompaniesSuccess: null,
  unblockCompaniesFail: '',
  adminActionDispatcher: admin_archive_step.ARCHIVE,
  stockCreditLines: [],
  stockCreditLinesIsFetching: false,
  stockCreditLinesError: '',
  isFetchingVendorsWithoutOffers: null,
  vendorsWithoutRelations: [],
  vendorsWithoutRelationsFail: '',
  sapErrors: null,
  isFetchingSapErrors: null,
  sapErrorsFail: null,
  isFetchingRetrySapErrors: null,
  isFetchingDeleteSapErrors: null,
  lastSapErrorTime: 0,
  lastSapErrorTimeForHighlight: 0,
  sapErrorsSilent: null,
  numberOfErrors: 0,
  invoices: [],
  isFetchingInvoices: false,
  isFetchingInvoicesSilent: false,
  computedCurrencyFilters: [],
};

const getCurrenciesFilters = (invoicesData: AdminInvoiceDTO[]) =>
  invoicesData?.map?.(item => item.currency).filter(onlyUniqueElements) || [];

export function adminReducer(
  state = defaultState,
  action: AdminAction
): AdminState {
  switch (action.type) {
    case SET_ADMIN_ACTION_DISPATCHER:
      return {
        ...state,
        adminActionDispatcher: action.payload,
      };
    case INVITE_CUSTOMER_REQUEST:
      return {
        ...state,
        isFetchingInviteCustomer: true,
        inviteCustomerResponse: {} as InviteCustomerDTOResponse,
        inviteCustomerError: '',
      };
    case INVITE_CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetchingInviteCustomer: false,
        inviteCustomerResponse: { status: 200, errorMessage: '' },
        inviteCustomerError: '',
      };
    case INVITE_CUSTOMER_FAIL:
      return {
        ...state,
        isFetchingInviteCustomer: false,
        inviteCustomerResponse: {} as InviteCustomerDTOResponse,
        inviteCustomerError: action.payload && action.payload.toString(),
      };
    case NEW_USER_STEP:
      return {
        ...state,
        newUserStep: action.payload,
      };
    case COMPANY_INFO_STEP:
      return {
        ...state,
        companyInfoStep: action.payload,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case GET_BLOCKED_USERS:
      return {
        ...state,
        isFetchingGetBlockedUsers: true,
        getBlockedUsersResponse: [],
        getBlockedUsersFail: [],
      };
    case GET_BLOCKED_USERS_SUCCESS:
      return {
        ...state,
        isFetchingGetBlockedUsers: false,
        getBlockedUsersResponse: action.payload as BlockedUserDTO,
        getBlockedUsersFail: [],
      };
    case GET_BLOCKED_USERS_FAIL:
      return {
        ...state,
        isFetchingGetBlockedUsers: false,
        getBlockedUsersResponse: [],
        getBlockedUsersFail: action.payload,
      };

    case UNBLOCK_PENDING:
      return {
        ...state,
        isFetchingUnblockPending: true,
        unblockPendingResponse: '',
        unblockPendingFail: '',
      };
    case UNBLOCK_PENDING_SUCCESS:
      return {
        ...state,
        isFetchingUnblockPending: false,
        unblockPendingResponse: action.payload,
        unblockPendingFail: '',
      };
    case UNBLOCK_PENDING_FAIL:
      return {
        ...state,
        isFetchingUnblockPending: false,
        unblockPendingResponse: '',
        unblockPendingFail: action.payload,
      };

    case REFUSE_PENDING:
      return {
        ...state,
        isFetchingRefusePending: true,
        refusePendingResponse: '',
        refusePendingFail: '',
      };
    case REFUSE_PENDING_SUCCESS:
      return {
        ...state,
        isFetchingRefusePending: false,
        refusePendingResponse: action.payload,
        refusePendingFail: '',
      };
    case REFUSE_PENDING_FAIL:
      return {
        ...state,
        isFetchingRefusePending: false,
        refusePendingResponse: '',
        refusePendingFail: action.payload,
      };

    case GET_SIGNERS:
      return {
        ...state,
        isFetchingGetSigners: true,
        getSignersSuccess: '',
        getSignersFail: '',
      };
    case GET_SIGNERS_SUCCESS:
      return {
        ...state,
        isFetchingGetSigners: false,
        getSignersSuccess: action.payload,
        getSignersFail: '',
      };
    case GET_SIGNERS_FAIL:
      return {
        ...state,
        isFetchingGetSigners: false,
        getSignersSuccess: '',
        getSignersFail: action.payload,
      };
    case TARIFF_REQUEST:
      return {
        ...state,
        tariffIsFetching: true,
        tariffResponse: defaultState.tariffResponse,
        tariffError: defaultState.tariffError,
      };
    case TARIFF_SUCCESS:
      return {
        ...state,
        tariffIsFetching: false,
        tariffResponse: action.payload,
        tariffError: '',
      };
    case TARIFF_FAIL:
      return {
        ...state,
        tariffIsFetching: false,
        tariffResponse: defaultState.tariffResponse,
        tariffError: action.payload,
      };
    case CASH_IMPROVEMENT_TARIFF_REQUEST:
      return {
        ...state,
        cashImprovementTariffIsFetching: true,
        cashImprovementTariffResponse:
          defaultState.cashImprovementTariffResponse,
        cashImprovementTariffError: defaultState.cashImprovementTariffError,
      };
    case CASH_IMPROVEMENT_TARIFF_SUCCESS:
      return {
        ...state,
        cashImprovementTariffIsFetching: false,
        cashImprovementTariffResponse: action.payload,
        cashImprovementTariffError: '',
      };
    case CASH_IMPROVEMENT_TARIFF_FAIL:
      return {
        ...state,
        cashImprovementTariffIsFetching: false,
        cashImprovementTariffResponse:
          defaultState.cashImprovementTariffResponse,
        cashImprovementTariffError: action.payload,
      };
    case SET_TARIFF_REQUEST:
      return {
        ...state,
        setTariffIsFetching: true,
        setTariffResponse: defaultState.tariffResponse,
        setTariffError: defaultState.tariffError,
      };
    case SET_TARIFF_SUCCESS:
      return {
        ...state,
        setTariffIsFetching: false,
        setTariffResponse: action.payload,
        setTariffError: '',
      };
    case SET_TARIFF_FAIL:
      return {
        ...state,
        setTariffIsFetching: false,
        setTariffResponse: defaultState.tariffResponse,
        setTariffError: action.payload,
      };

    case UPDATE_TARIFF_REQUEST:
      return {
        ...state,
        updateTariffIsFetching: true,
        updateTariffResponse: defaultState.updateTariffResponse,
        updateTariffError: defaultState.updateTariffError,
      };
    case UPDATE_TARIFF_SUCCESS:
      return {
        ...state,
        updateTariffIsFetching: false,
        updateTariffResponse: action.payload,
        updateTariffError: '',
      };
    case UPDATE_TARIFF_FAIL:
      return {
        ...state,
        updateTariffIsFetching: false,
        updateTariffResponse: defaultState.updateTariffResponse,
        updateTariffError: action.payload,
      };

    case GET_CUSTOMERS:
      return {
        ...state,
        isFetchingGetCustomers: true,
        getCustomersSuccess: defaultState.getCustomersSuccess,
        getCustomersFail: defaultState.getCustomersFail,
      };
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        isFetchingGetCustomers: false,
        getCustomersSuccess: action.payload,
        getCustomersFail: defaultState.getCustomersFail,
      };
    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        isFetchingGetCustomers: false,
        getCustomersSuccess: defaultState.getCustomersSuccess,
        getCustomersFail: action.payload,
      };
    case GET_USERS_REQUEST:
      return {
        ...state,
        getUsersIsFetching: true,
        getUsersSuccess: defaultState.getUsersSuccess,
        getUsersFail: defaultState.getUsersFail,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        getUsersIsFetching: false,
        getUsersSuccess: action.payload,
        getUsersFail: defaultState.getUsersFail,
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        getUsersIsFetching: false,
        getUsersSuccess: defaultState.getUsersSuccess,
        getUsersFail: action.payload,
      };
    case BLOCK_USER_REQUEST:
      return {
        ...state,
        blockUsersIsFetching: true,
        blockUsersSuccess: defaultState.blockUsersSuccess,
        blockUsersFail: defaultState.blockUsersFail,
      };
    case BLOCK_USER_SUCCESS:
      return {
        ...state,
        blockUsersIsFetching: false,
        blockUsersSuccess: action.payload,
        blockUsersFail: defaultState.blockUsersFail,
      };
    case BLOCK_USER_FAIL:
      return {
        ...state,
        blockUsersIsFetching: false,
        blockUsersSuccess: defaultState.blockUsersSuccess,
        blockUsersFail: action.payload,
      };
    case UNBLOCK_USER_REQUEST:
      return {
        ...state,
        unblockUserIsFetching: true,
        unblockUserSuccess: defaultState.blockUsersSuccess,
        unblockUserFail: defaultState.blockUsersFail,
      };
    case UNBLOCK_USER_SUCCESS:
      return {
        ...state,
        unblockUserIsFetching: false,
        unblockUserSuccess: action.payload,
        unblockUserFail: defaultState.blockUsersFail,
      };
    case UNBLOCK_USER_FAIL:
      return {
        ...state,
        unblockUserIsFetching: false,
        unblockUserSuccess: defaultState.blockUsersSuccess,
        unblockUserFail: action.payload,
      };
    case GET_COMPANIES_REQUEST:
      return {
        ...state,
        getCompaniesIsFetching: true,
        getCompaniesSuccess: defaultState.getCompaniesSuccess,
        getCompaniesFail: defaultState.getCompaniesFail,
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        getCompaniesIsFetching: false,
        getCompaniesSuccess: action.payload,
        getCompaniesFail: defaultState.getCompaniesFail,
      };
    case GET_COMPANIES_FAIL:
      return {
        ...state,
        getCompaniesIsFetching: false,
        getCompaniesSuccess: defaultState.getCompaniesSuccess,
        getCompaniesFail: action.payload,
      };
    case BLOCK_COMPANIES_REQUEST:
      return {
        ...state,
        blockCompaniesIsFetching: true,
        blockCompaniesSuccess: defaultState.blockCompaniesSuccess,
        blockCompaniesFail: defaultState.blockCompaniesFail,
      };
    case BLOCK_COMPANIES_SUCCESS:
      return {
        ...state,
        blockCompaniesIsFetching: false,
        blockCompaniesSuccess: action.payload,
        blockCompaniesFail: defaultState.blockCompaniesFail,
      };
    case BLOCK_COMPANIES_FAIL:
      return {
        ...state,
        blockCompaniesIsFetching: false,
        blockCompaniesSuccess: defaultState.blockCompaniesSuccess,
        blockCompaniesFail: action.payload,
      };
    case UNBLOCK_COMPANIES_REQUEST:
      return {
        ...state,
        unblockCompaniesIsFetching: true,
        unblockCompaniesSuccess: defaultState.blockCompaniesSuccess,
        unblockCompaniesFail: defaultState.blockCompaniesFail,
      };
    case UNBLOCK_COMPANIES_SUCCESS:
      return {
        ...state,
        unblockCompaniesIsFetching: false,
        unblockCompaniesSuccess: action.payload,
        unblockCompaniesFail: defaultState.blockCompaniesFail,
      };
    case UNBLOCK_COMPANIES_FAIL:
      return {
        ...state,
        unblockCompaniesIsFetching: false,
        unblockCompaniesSuccess: defaultState.blockCompaniesSuccess,
        unblockCompaniesFail: action.payload,
      };
    case GET_ONBOARDING_STATUS:
      return {
        ...state,
        isFetchingGetOnboardingStatus: true,
        getOnboardingStatusSuccess: defaultState.getOnboardingStatusSuccess,
        getOnboardingStatusFail: defaultState.getOnboardingStatusFail,
      };
    case GET_ONBOARDING_STATUS_SUCCESS:
      return {
        ...state,
        isFetchingGetOnboardingStatus: false,
        getOnboardingStatusSuccess: action.payload as OnBoardingStatusDTO[],
        getOnboardingStatusFail: defaultState.getOnboardingStatusFail,
      };
    case GET_ONBOARDING_STATUS_FAIL:
      return {
        ...state,
        isFetchingGetOnboardingStatus: false,
        getOnboardingStatusSuccess: defaultState.getOnboardingStatusSuccess,
        getOnboardingStatusFail: action.payload,
      };
    case STOCK_CREDIT_LINES_REQUEST:
      return {
        ...state,
        stockCreditLines: [],
        stockCreditLinesIsFetching: true,
        stockCreditLinesError: defaultState.stockCreditLinesIsFetching,
      };
    case STOCK_CREDIT_LINES_SUCCESS:
      return {
        ...state,
        stockCreditLines: action.payload as StockCreditLine[],
        stockCreditLinesIsFetching: false,
        stockCreditLinesError: defaultState.stockCreditLinesError,
      };
    case STOCK_CREDIT_LINES_FAIL:
      return {
        ...state,
        stockCreditLines: [],
        stockCreditLinesIsFetching: false,
        stockCreditLinesError: action.payload,
      };
    case VENDORS_WITHOUT_RELATIONS:
      return {
        ...state,
        isFetchingVendorsWithoutOffers: true,
        vendorsWithoutRelations: [],
        vendorsWithoutRelationsFail: defaultState.stockCreditLinesIsFetching,
      };
    case VENDORS_WITHOUT_RELATIONS_SUCCESS:
      return {
        ...state,
        isFetchingVendorsWithoutOffers: false,
        vendorsWithoutRelations: action.payload as string[],
        vendorsWithoutRelationsFail: defaultState.stockCreditLinesError,
      };
    case VENDORS_WITHOUT_RELATIONS_FAIL:
      return {
        ...state,
        isFetchingVendorsWithoutOffers: null,
        vendorsWithoutRelations: [],
        vendorsWithoutRelationsFail: action.payload,
      };
    case GET_SAP_ERROR:
      return {
        ...state,
        isFetchingSapErrors: true,
      };
    case GET_SAP_ERROR_SUCCESS:
      return {
        ...state,
        isFetchingSapErrors: false,
        sapErrors: action.payload as SapErrorDTO,
        numberOfErrors: action.payload?.count,
      };

    case GET_SAP_ERROR_FAIL:
      return {
        ...state,
        isFetchingSapErrors: null,
        sapErrorsFail: action.payload,
      };

    case RETRY_SAP_ERROR:
      return {
        ...state,
        isFetchingRetrySapErrors: true,
      };
    case RETRY_SAP_ERROR_SUCCESS:
      return {
        ...state,
        isFetchingRetrySapErrors: false,
      };

    case RETRY_SAP_ERROR_FAIL:
      return {
        ...state,
        isFetchingRetrySapErrors: null,
      };

      case DELETE_SAP_ERROR:
      return {
        ...state,
        isFetchingDeleteSapErrors: true,
      };
    case DELETE_SAP_ERROR_SUCCESS:
      return {
        ...state,
        isFetchingDeleteSapErrors: false,
      };

    case DELETE_SAP_ERROR_FAIL:
      return {
        ...state,
        isFetchingDeleteSapErrors: null,
      };

    case CLEAR_TARIFF_ERROR:
      return {
        ...state,
        setTariffError: null,
      };

    case GET_SAP_ERROR_SUCCESS_SILENT:
      if (state.lastSapErrorTime < action.payload?.errors?.[0]?.receiveDate) {
        toastUtils.warningToast(i18n.t('common.sapError'));
      }
      return {
        ...state,
        lastSapErrorTime: action.payload?.errors?.[0]?.receiveDate,
        numberOfErrors: action.payload?.count,
      };
    case SET_LAST_DATE_SAP_ARRIVAL_HIGHLIGHT:
      return {
        ...state,
        lastSapErrorTimeForHighlight: action.payload,
      };
    case GET_ADMIN_INVOICES:
      return {
        ...state,
        isFetchingInvoices: true,
      };
    case GET_ADMIN_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        computedCurrencyFilters: getCurrenciesFilters(
          action.payload.invoicesData
        ),
        isFetchingInvoices: false,
      };
    case GET_ADMIN_INVOICES_FAIL:
      return {
        ...state,
        invoices: null,
        isFetchingInvoices: false,
      };

    case GET_ADMIN_INVOICES_SILENT:
      return {
        ...state,
        isFetchingInvoicesSilent: true,
      };
    case GET_ADMIN_INVOICES_SUCCESS_SILENT:
      return {
        ...state,
        invoices: action.payload,
        computedCurrencyFilters: getCurrenciesFilters(
          action.payload.invoicesData
        ),
        isFetchingInvoicesSilent: false,
      };
    case GET_ADMIN_INVOICES_FAIL_SILENT:
      return {
        ...state,
        invoices: null,
        isFetchingInvoicesSilent: false,
      };

    default:
      return state;
  }
}

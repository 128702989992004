import * as React from 'react';
import { AppState } from '../../redux/store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import CardContainerWithButtons from '../Common/CardContainerWithButtons/CardContainerWithButtons';
import { timeUtils } from '../../utils/timeUtils';
import { useTranslation } from 'react-i18next';
import './CreateCreditLine.scss';
import ContainerRow from '../Common/ContainerRow/ContainerRow';
import MyDropdown from '../Common/MyDropdown/MyDropdown';
import TextInput, { TextInputType } from '../Common/TextInput/TextInput';
import {
  AvailableProgramDTO,
  AvailableProgramsResponse,
  ProgramDTO,
  VendorToDisplay,
} from '../../model/ProgramDTO';
import Table from '../Common/Table/Table';
import '../Common/Table/Table.scss';
import CheckBox from '../Common/CheckBox/CheckBox';
import { currencyUtils } from '../../utils/currencyUtils';
import ReactMarkdown from 'react-markdown';
import { countryUtils } from '../../utils/countryUtils';
import ScrollableTable from '../Common/ScrollableTable/ScrollableTable';
import { numberUtils } from '../../utils/numberUtils';
import { isAtLeastOneProgramSelected, isNumber } from '../../utils/commonUtils';
import Button, { BUTTON_TYPE } from '../Common/Button/Button';
import { getAllBuyersForMoneylender } from '../../redux/company';
import { CompanyInfoDTO } from '../../model/CompanyDTO';
import {
  getCurrencyEnabled,
  getProgramsAvailable,
} from '../../redux/program/program-actions';
import {
  CreateCreditLineMoneylenderRequestDTO,
  DelayRateType,
  DurationPayablesType,
} from '../../model/MoneylenderDTO';
import { VendorCountryDTO } from '../../model/DynamicDiscountDTO';
import '../CreateDynamicDiscount/CreateDynamicDiscount.scss';
import '../../screens/onBoarding/company/effectiveOwner/OnBoardingEffectiveOwner.scss';
import tick from '../../assets/Icons/tick.svg';
import warning from '../../assets/Icons/warning.svg';
import {
  getCreditLineProgramsRow,
  stylesCreditLineTable,
} from '../../screens/moneylender/CreditLineDetails/CreditLineDetails';
import Ellapsis from '../Common/Ellapsis/Ellapsis';
import NumericInput, {
  NumericInputType,
} from '../Common/NumericInput/NumericInput';

interface Props {
  availablePrograms: AvailableProgramsResponse;
  [index: string]: any;
}

enum SelectProgramsType {
  ALL_PROGRAMS = 'ALL_PROGRAMS',
  SELECT_PROGRAMS = 'SELECT_PROGRAMS',
}

export enum SublimitType {
  PRIMARY_MARKET = 1,
  ALL = 2,
  SECONDARY_MARKET = 3,
}

const CreateCreditLine: React.FC<Props> = ({
  onConfirm,
  onClose,
  error: validationError,
  resetError,
  retrieveAllBuyer,
  buyers,
  getProgramsAvailable,
  programs,
  getCurrencyEnabled,
  currencies,
  allProgramsEnabled,
  countryList,
  isFetchingAllProgramsAvailable,
  creditLineDetails,
  creditLineId,
}) => {
  const [data, setData] = React.useState({} as any);
  const [cashImprovementCheckboxSelected, setCashImprovementCheckboxSelected] =
    React.useState(false);
  const [vendorCountryList, setVendorCountryList] = React.useState(
    [] as VendorCountryDTO[]
  );
  const [programsToDisplay, setProgramsToDisplay] = React.useState(
    [] as AvailableProgramDTO[]
  );
  const [errorPaymentMethod, setErrorPaymentMethod] = React.useState<
    boolean | null
  >();

  const { t } = useTranslation();
  const visibilityInputValues = [t('common.button.yes'), t('common.button.no')];
  const discountModeInputValues = [
    t('moneylender.creditLine.mode1'),
    t('moneylender.creditLine.mode2'),
  ];
  const ibantools = require('ibantools');

  const initVendorsCountries = () => {
    if (countryList?.length > 0) {
      const vendorsToDisplay: VendorToDisplay[] = countryList?.map(
        (country: string) => ({ country, selected: false })
      );
      setVendorCountryList(vendorsToDisplay);
    }
  };

  React.useEffect(() => {
    initVendorsCountries();
    resetError('');
    // eslint-disable-next-line
  }, [programs]);

  React.useEffect(() => {
    if (creditLineDetails) {
      const newData = { ...creditLineDetails };
      getCurrencyEnabled();
      getProgramsAvailable(
        creditLineDetails.currency,
        creditLineDetails.vatNumber
      );
      if (creditLineDetails.iban) {
        newData.typeOfPayment = 'IBAN';
        setErrorPaymentMethod(
          !ibantools.isValidIBAN(
            ibantools.electronicFormatIBAN(creditLineDetails.iban)
          )
        );
      }
      if (creditLineDetails.swift) {
        newData.typeOfPayment = 'SWIFT';
        setErrorPaymentMethod(!ibantools.isValidBIC(creditLineDetails.swift));
      }
      if (creditLineDetails.countryList) {
        setVendorCountryList(creditLineDetails.countryList);
      }
      setData(newData);
    }

    // eslint-disable-next-line
  }, [creditLineDetails]);

  const onCheckMoneylenderRow = ({ countryName }: any) => {
    const vendorsModified = [...vendorCountryList];
    const clickedItem =
      vendorsModified.find(
        c => c.country === countryUtils.fromNameToISOCode(countryName)
      ) || ({} as VendorCountryDTO);
    clickedItem.selected = !clickedItem.selected;
    setVendorCountryList(vendorsModified);
    let moneylenderWhiteList: string[] = [];
    vendorsModified.forEach(
      ({ selected, country }: VendorCountryDTO) =>
        selected && moneylenderWhiteList.push(country)
    );
    updateData({ moneylenderWhiteList });
  };

  const updateData = (inputData: {}) => {
    resetError();
    setData({ ...data, ...inputData });
  };

  React.useEffect(() => {
    if (!validationError) retrieveAllBuyer();
    // eslint-disable-next-line
  }, [retrieveAllBuyer]);

  React.useEffect(() => {
    updateSublimitType(data.secondaryMarket);
    // eslint-disable-next-line
  }, [data.amount, data.secondaryMarket]);

  React.useEffect(() => {
    const filteredPrograms = programs?.filter?.(
      ({ currency }: any) => currency === data.currency
    );
    filteredPrograms?.forEach?.((item: any) => (item.selected = false));
    setProgramsToDisplay(filteredPrograms || []);
    // eslint-disable-next-line
  }, [data.currency, programs]);

  const onCheckboxClick = (programSelected: ProgramDTO) => {
    const newItem = { ...programSelected, selected: true } as any;
    let programList = [...programsToDisplay];
    programList.forEach((program, index: number) => {
      if (program.programName === newItem.programName) {
        programList[index].selected
          ? (programList[index].selected = false)
          : (programList[index].selected = true);
      }
    });
    updateData({ programsToDisplay: programList });
  };

  const clearPrograms = () => {
    let programList = [...programsToDisplay];
    programList?.forEach?.(program => (program.selected = false));
    updateData({ programsToDisplay: [] });
  };

  const headersProgramTable = [
    { label: '', style: stylesCreditLineTable.checkbox },
    {
      label: t('dynamicDiscount.newDynamicDiscount.programTable.headers.id'),
      style: stylesCreditLineTable.id,
    },
    {
      label: t(
        'dynamicDiscount.newDynamicDiscount.programTable.headers.description'
      ),
      style: stylesCreditLineTable.description,
    },
    {
      label: t(
        'dynamicDiscount.newDynamicDiscount.programTable.headers.currency'
      ),
      style: stylesCreditLineTable.currency,
    },
    {
      label: t('dynamicDiscount.newDynamicDiscount.programTable.headers.delay'),
      style: stylesCreditLineTable.delay,
    },
    {
      label: t(
        'dynamicDiscount.newDynamicDiscount.programTable.headers.delayRate'
      ),
      style: stylesCreditLineTable.delayRate,
    },
    {
      label: t('dynamicDiscount.newDynamicDiscount.programTable.headers.tax'),
      style: stylesCreditLineTable.tax,
    },
    {
      label: t(
        'dynamicDiscount.newDynamicDiscount.programTable.headers.spread'
      ),
      style: stylesCreditLineTable.spread,
    },
  ];

  const renderTableRow = (item: any, index: number, array: any) => {
    const {
      programName,
      description,
      currency,
      daysOfDelay,
      delayRate,
      selected,
    } = item;
    const daysOfDelayToDisplay =
      daysOfDelay === 1
        ? daysOfDelay + ' ' + t('moneylender.creditLine.day')
        : daysOfDelay + ' ' + t('moneylender.creditLine.days');
    const delayRateValue = delayRate.variableBaseRate
      ? t(`rates.${delayRate.variableBaseRate}`)
      : numberUtils.reverseAndFormatToLocaleNumber(delayRate.fixedRate) + '%';
    const spread =
      delayRate.type !== DelayRateType.FIXED
        ? numberUtils.formatToPercent(delayRate.variableSpreadRate)
        : '-';
    return (
      <div key={index}>
        <div
          key={index}
          className="createCreditLine__tableRow"
          onClick={() =>
            !selected && isOneProgramSelected && cashImprovementCheckboxSelected
              ? undefined
              : onCheckboxClick(item)
          }
        >
          <span className="table__item" style={stylesCreditLineTable.checkbox}>
            <CheckBox
              checked={selected}
              disabled={
                !selected &&
                isOneProgramSelected &&
                cashImprovementCheckboxSelected
              }
            />
          </span>
          <span
            title={programName}
            className="table__item"
            style={stylesCreditLineTable.id}
          >
            {programName}
          </span>
          <span
            title={description || '-'}
            className="table__item table__item--moreSpace"
            style={stylesCreditLineTable.description}
          >
            <Ellapsis>{description || '-'}</Ellapsis>
          </span>
          <span
            title={currency}
            className="table__item"
            style={stylesCreditLineTable.currency}
          >
            {currency}
          </span>
          <span
            title={daysOfDelayToDisplay}
            className="table__item"
            style={stylesCreditLineTable.delay}
          >
            {daysOfDelayToDisplay}
          </span>
          <span
            title={t(`SCFProgram.modal.${delayRate.type}`)}
            className="table__item"
            style={stylesCreditLineTable.delayRate}
          >
            {t(`SCFProgram.modal.${delayRate.type}`)}
          </span>
          <span
            title={delayRateValue}
            className="table__item"
            style={stylesCreditLineTable.tax}
          >
            {delayRateValue}
          </span>
          <span
            title={spread}
            className="table__item"
            style={stylesCreditLineTable.spread}
          >
            {spread}
          </span>
        </div>
        <div
          key={index + 'line'}
          className={`table__line ${
            index === array.length - 1 ? 'table__line--last' : ''
          }`}
        />
      </div>
    );
  };

  const handleSublimit = (sublimit: number) => {
    if (data.secondaryMarket === SublimitType.ALL) {
      const maxAmount = isModification ? data.originalAmount : data.amount;
      sublimit = sublimit <= maxAmount ? sublimit : maxAmount;
      updateData({ sublimit });
    }
  };

  const updateSublimitType = (secondaryMarket: SublimitType) => {
    switch (secondaryMarket) {
      case SublimitType.PRIMARY_MARKET:
        updateData({ sublimit: 0, secondaryMarket });
        break;
      case SublimitType.ALL:
        updateData({
          sublimit: isModification ? creditLineDetails.sublimit : null,
          secondaryMarket,
        });
        break;
      case SublimitType.SECONDARY_MARKET:
        updateData({ sublimit: data.amount, secondaryMarket });
        break;
    }
  };
  const isModification = !!creditLineDetails;
  let isOneProgramSelected =
    isAtLeastOneProgramSelected(programsToDisplay) || false;
  const {
    businessName,
    buyerId,
    buyerRelationId,
    currency,
    amount,
    revolving,
    warnings,
    discount,
    durationPayables,
    sublimit,
    secondaryMarket,
    visibility,
    moneylenderWhiteList,
    allPrograms,
    creditLineName,
    durationPayablesType,
  } = data;

  const disabledButton = !(
    businessName &&
    buyerId &&
    buyerRelationId &&
    currency &&
    (isModification || amount) &&
    revolving !== undefined &&
    warnings &&
    discount &&
    durationPayables &&
    isNumber(sublimit) &&
    secondaryMarket &&
    creditLineName &&
    durationPayablesType &&
    errorPaymentMethod === false &&
    (visibility === false ||
      (visibility === true && moneylenderWhiteList?.length > 0)) &&
    ((!isModification &&
      (allPrograms === SelectProgramsType.ALL_PROGRAMS ||
        isOneProgramSelected)) ||
      isModification)
  );
  const submitCreateCreditLine = () => {
    let programs = [] as { programName: string }[];
    data?.programsToDisplay?.forEach?.(
      ({ programName, selected }: any) =>
        selected === true && programs.push({ programName })
    );
    const newData = { ...data } as CreateCreditLineMoneylenderRequestDTO;
    newData.programs = programs;
    newData.iban = data?.iban?.replace(/\s+/g, '');
    newData.amount = numberUtils.reverseFromLocaleToNumber(data.amount);
    newData.sublimit = numberUtils.reverseFromLocaleToNumber(data.sublimit);
    newData.allPrograms = allPrograms === SelectProgramsType.ALL_PROGRAMS;
    newData.durationPayablesType =
      data.durationPayablesType === 1
        ? DurationPayablesType.MAX_RESIDUAL_DURATION
        : DurationPayablesType.MAX_ORIGINAL_DURATION;
    if (creditLineDetails && creditLineId) newData.creditLineId = creditLineId;
    onConfirm?.(newData);
  };

  const selectProgramsLinks: SelectProgramsType[] = [];
  let description: string = '';
  let blockCreationCreditLine = false;

  if (creditLineDetails) {
    if (creditLineDetails.programs?.length > 0) {
      selectProgramsLinks.push(SelectProgramsType.SELECT_PROGRAMS);
    } else if (creditLineDetails.allPrograms) {
      selectProgramsLinks.push(SelectProgramsType.ALL_PROGRAMS);
    }
  } else {
    if (allProgramsEnabled === true) {
      if (programs?.length > 0) {
        selectProgramsLinks.push(
          SelectProgramsType.ALL_PROGRAMS,
          SelectProgramsType.SELECT_PROGRAMS
        );
        description = t('moneylender.creditLine.scfProgramsDescription');
      } else {
        selectProgramsLinks.push(SelectProgramsType.ALL_PROGRAMS);
        description = t(
          'moneylender.creditLine.scfProgramsDescriptionAllPrograms'
        );
      }
    } else if (allProgramsEnabled === false) {
      if (programs?.length) {
        selectProgramsLinks.push(SelectProgramsType.SELECT_PROGRAMS);
        description = t(
          'moneylender.creditLine.scfProgramsDescriptionSelectPrograms'
        );
      } else blockCreationCreditLine = true;
    }
  }

  return (
    <div className="createCreditLine">
      <CardContainerWithButtons
        title={
          creditLineDetails ? (
            <div
              style={{
                marginLeft: '40px',
                marginRight: '40px',
                marginBottom: '20px',
              }}
            >
              <span className="moneylenderVendorInfo__topSection">
                <span className="moneylenderVendorInfo__title">
                  {t('moneylender.creditLine.titleCard') + data.creditLineName}
                </span>
                <span
                  className="moneylenderVendorInfo__rightSide"
                  style={{ alignItems: 'center' }}
                >
                  <div className="moneylenderVendorInfo__rightSideSub">
                    <div className="moneylenderVendorInfo__firstChild">
                      {t('moneylender.creditLine.creditLineCode')}
                    </div>
                    <div className="moneylenderVendorInfo__secondChild">
                      <Ellapsis title={data.creditLineCode || '-'} />
                    </div>
                  </div>
                  <div className="moneylenderVendorInfo__rightSideSub">
                    <div className="moneylenderVendorInfo__firstChild">
                      {t('SCFProgram.creationDate')}
                    </div>
                    <div className="moneylenderVendorInfo__secondChild">
                      {timeUtils.formatToLocaleDate(data.creationDate) || '-'}
                    </div>
                  </div>
                  <div className="moneylenderVendorInfo__rightSideSub">
                    <div className="moneylenderVendorInfo__firstChild">
                      {t('dynamicDiscount.creditLineList.residual') + ': '}
                    </div>
                    <div className="moneylenderVendorInfo__title">
                      {numberUtils.formatToLocaleNumber(data.residualAmount) ||
                        '-'}{' '}
                      {data.currency}
                    </div>
                  </div>
                </span>
              </span>
              <div className="line" />
            </div>
          ) : (
            t('moneylender.creditLine.createCreditLine')
          )
        }
        creationDate={timeUtils.getLocalTime()}
        error={validationError}
        confirmButton={
          <div className="containerButtonConfirm containerButtonConfirm--marginRight80">
            <Button
              label={t('moneylender.creditLine.generate')}
              onClick={submitCreateCreditLine}
              disabled={disabledButton}
              width={'100%'}
            />
          </div>
        }
        buttonAbort={
          <div className="containerButtonConfirm containerButtonConfirm--marginRight20">
            <Button
              label={t('common.button.abortButton')}
              onClick={onClose}
              buttonType={BUTTON_TYPE.primaryInverted}
              width={'100%'}
            />
          </div>
        }
      >
        {!isModification && (
          <ContainerRow>
            <div className="inputContainer">
              <TextInput
                title={t('moneylender.creditLine.creditLineName') + '*'}
                value={data.creditLineName}
                maxLength={30}
                onChange={(creditLineName: string) =>
                  updateData({ creditLineName })
                }
                placeholder={t('common.inputField.placeholder')}
                inputType={TextInputType.ALL_CHARACTER}
              />
            </div>
          </ContainerRow>
        )}
        {/* 1 ELEMNET*/}
        <ContainerRow>
          <div className="inputContainer">
            {!isModification ? (
              <MyDropdown
                title={t('supplier.activeInvoices.company') + '*'}
                placeholder={t('moneylender.creditLine.buyerPlaceholder')}
                links={buyers}
                labelExtractor={(item: CompanyInfoDTO) => item?.businessName}
                inputValue={data.businessName}
                onChange={({ businessName, vatNumber }: CompanyInfoDTO) => {
                  updateData({ businessName, vatNumber, currency: '' });
                  getCurrencyEnabled();
                }}
              />
            ) : (
              <TextInput
                title={t('moneylender.creditLine.buyer')}
                value={data.businessName}
                disableInput
              />
            )}
          </div>
          <div className="inputContainer">
            <TextInput
              disableInput={isModification}
              title={t('moneylender.creditLine.idBuyer') + '*'}
              placeholder={t('moneylender.creditLine.idBuyerPlaceholder')}
              onChange={(buyerId: string) => updateData({ buyerId })}
              value={data.buyerId}
            />
          </div>
          <div className="inputContainer">
            <TextInput
              disableInput={isModification}
              title={t('moneylender.creditLine.idRegisteredToBuyer') + '*'}
              placeholder={t(
                'moneylender.creditLine.idRegisteredToBuyerPlaceholder'
              )}
              onChange={(buyerRelationId: string) =>
                updateData({ buyerRelationId })
              }
              value={data.buyerRelationId}
            />
          </div>
        </ContainerRow>

        {/* 2 ELEMENT CURRENCY*/}
        {data.vatNumber && (
          <>
            <div
              className="customDropdown__titleContainer"
              style={{ marginTop: '20px' }}
            >
              <div className="customDropdown__title">
                {t('moneylender.creditLine.currency') + '*'}
              </div>
            </div>
            <div className="createCreditLine__descriptionInput">
              {t('moneylender.creditLine.currencyDescription')}
            </div>
            <ContainerRow>
              <div className="inputContainer">
                {isModification ? (
                  <TextInput
                    title={t('moneylender.creditLine.currency')}
                    value={data.currency}
                    disableInput
                  />
                ) : (
                  <MyDropdown
                    placeholder={t(
                      'moneylender.creditLine.currencyPlaceholder'
                    )}
                    links={currencies}
                    inputValue={data.currency}
                    onChange={(currency: string) => {
                      if (currency !== data.currency)
                        updateData({
                          currency,
                          amount: null,
                          revolving: null,
                          warnings: null,
                          discount: null,
                          expiryDate: null,
                          durationPayables: null,
                          visibility: null,
                          secondaryMarket: null,
                          sublimit: '',
                          allPrograms: null,
                        });
                      getProgramsAvailable(currency, data.vatNumber);
                    }}
                  />
                )}
              </div>
              <div className="inputContainer" />
              <div className="inputContainer" />
            </ContainerRow>
          </>
        )}
        {blockCreationCreditLine && (
          <div className={'createCreditLine__error'}>
            {t('moneylender.creditLine.impossibleCreateCreditLine')}
          </div>
        )}

        {!blockCreationCreditLine &&
          isFetchingAllProgramsAvailable === false &&
          data.vatNumber &&
          data.currency && (
            <>
              {/* 3 ALL PROGRAMS OR SELECT PROGRAMS */}
              {!isModification && (
                <>
                  <div
                    className="customDropdown__titleContainer"
                    style={{ marginTop: '20px' }}
                  >
                    <div className="customDropdown__title">
                      {t('moneylender.creditLine.scfPrograms') + '*'}
                    </div>
                  </div>
                  <div className="createCreditLine__descriptionInput">
                    <ReactMarkdown source={description} escapeHtml={false} />
                  </div>
                  <ContainerRow>
                    <div className="inputContainer">
                      <MyDropdown
                        inputValue={data?.allPrograms}
                        links={selectProgramsLinks}
                        labelExtractor={(programsType: SelectProgramsType) =>
                          programsType &&
                          t(`moneylender.creditLine.${programsType}`)
                        }
                        onChange={(programsType: SelectProgramsType) => {
                          clearPrograms();
                          setCashImprovementCheckboxSelected(false);
                          updateData({ allPrograms: programsType });
                        }}
                        editable={!cashImprovementCheckboxSelected}
                      />
                    </div>
                    {!isModification &&
                      data?.allPrograms ===
                        SelectProgramsType.SELECT_PROGRAMS &&
                      programsToDisplay.find(
                        program => program.programType === 3
                      ) && (
                        <CheckBox
                          description={'Cash Improvement SCF Program Type'}
                          descriptionStyle={{ marginRight: '30px' }}
                          onClickCheckbox={() => {
                            clearPrograms();
                            setCashImprovementCheckboxSelected(
                              cashImprovementCheckboxSelected =>
                                !cashImprovementCheckboxSelected
                            );
                          }}
                          checked={cashImprovementCheckboxSelected}
                        />
                      )}
                    <div className="inputContainer" />
                    <div className="inputContainer" />
                  </ContainerRow>
                </>
              )}

              {!isModification &&
                data?.allPrograms === SelectProgramsType.SELECT_PROGRAMS && (
                  <>
                    {/* 3 ELEMENT TABLE*/}
                    <Table
                      renderItem={renderTableRow}
                      headers={headersProgramTable}
                      listToDisplay={
                        cashImprovementCheckboxSelected
                          ? programsToDisplay.filter(
                              program => program.programType === 3
                            )
                          : programsToDisplay.filter(
                              program => program.programType !== 3
                            )
                      }
                      headerClassNameModifier="table__header--noPadding"
                    />
                    <div
                      className={`createCreditLine__error ${
                        isOneProgramSelected &&
                        'createCreditLine__error--errorDisabled'
                      }`}
                      style={{ marginTop: '20px' }}
                    >
                      {t('dynamicDiscount.newDynamicDiscount.errorProgram')}
                    </div>
                  </>
                )}

              {isModification && (
                <>
                  <div
                    className="customDropdown__titleContainer"
                    style={{ marginTop: '20px' }}
                  >
                    <div className="customDropdown__title">
                      {t('moneylender.creditLine.scfProgramsModify')}
                    </div>
                  </div>
                  {creditLineDetails.programs && (
                    <Table
                      renderItem={(item, index, array) =>
                        getCreditLineProgramsRow(item, index, array, t)
                      }
                      headers={headersProgramTable.map((header: any) => {
                        return { label: t(header.label), style: header.style };
                      })}
                      listToDisplay={creditLineDetails.programs}
                      headerClassNameModifier="table__header--noPadding"
                    />
                  )}
                  {creditLineDetails.allPrograms && (
                    <ContainerRow>
                      <div className="inputContainer">
                        <TextInput
                          value={t(
                            `moneylender.creditLine.${SelectProgramsType.ALL_PROGRAMS}`
                          )}
                          disableInput
                        />
                      </div>
                      <div className="inputContainer" />
                      <div className="inputContainer" />
                    </ContainerRow>
                  )}
                </>
              )}

              {/* 4 ELEMENT AMOUNT CREDIT LINE*/}
              {!isModification && (
                <ContainerRow>
                  <div
                    className="inputContainer"
                    style={{
                      marginTop: isOneProgramSelected ? '-30px' : '20px',
                    }}
                  >
                    <NumericInput
                      inputType={NumericInputType.UNSIGNED_NUMBER}
                      maxLength={9}
                      title={
                        t('moneylender.creditLine.amountOfCreditLine') + '*'
                      }
                      symbol={currencyUtils.getSymbolFromCurrencyISO(
                        data.currency
                      )}
                      value={data.amount}
                      placeholder={t(
                        'dynamicDiscount.newDynamicDiscount.amountPlaceholder'
                      )}
                      onChange={amount => updateData({ amount })}
                    />
                  </div>
                  <div className="inputContainer" />
                  <div className="inputContainer" />
                </ContainerRow>
              )}
              {isModification && (
                <ContainerRow>
                  <div className="inputContainer">
                    <TextInput
                      title={t('moneylender.creditLine.amountOfCreditLine')}
                      value={data.amount}
                      symbol={data.currency}
                      disableInput
                    />
                  </div>
                  <div className="inputContainer">
                    <NumericInput
                      inputType={NumericInputType.UNSIGNED_NUMBER}
                      maxLength={9}
                      title={t(
                        'moneylender.creditLine.originalAmountOfCreditLine'
                      )}
                      value={data.originalAmount}
                      symbol={data.currency}
                      onChange={originalAmount =>
                        updateData({ originalAmount })
                      }
                    />
                  </div>
                  <div className="inputContainer" />
                </ContainerRow>
              )}

              {/*CHOOSE IBAN or SWIFT*/}
              <ContainerRow style={{ marginTop: '20px' }}>
                <div className="inputContainer">
                  <MyDropdown
                    title={
                      t(
                        'dynamicDiscount.newDynamicDiscount.choosePaymentMethod'
                      ) + '*'
                    }
                    inputValue={data.typeOfPayment || ''}
                    links={['IBAN', 'SWIFT']}
                    onChange={(typeOfPayment: string) => {
                      updateData({ typeOfPayment, iban: null, swift: null });
                      setErrorPaymentMethod(null);
                    }}
                  />
                </div>
                <div className="inputContainer" />
                <div className="inputContainer" />
              </ContainerRow>

              {/*IBAN*/}
              {data.typeOfPayment && (
                <ContainerRow>
                  <div className="inputContainer">
                    {data.typeOfPayment === 'IBAN' ? (
                      <TextInput
                        placeholder={t(
                          'dynamicDiscount.newDynamicDiscount.ibanPlaceholder'
                        )}
                        title={t('dynamicDiscount.creditLineInfo.iban') + '*'}
                        value={data.iban}
                        onChange={(iban: string) => {
                          setErrorPaymentMethod(
                            !ibantools.isValidIBAN(
                              ibantools.electronicFormatIBAN(iban)
                            )
                          );
                          updateData({ iban: iban.toUpperCase().trim() });
                        }}
                        symbol={
                          data.iban &&
                          typeof errorPaymentMethod === 'boolean' && (
                            <img
                              alt="tick"
                              src={!errorPaymentMethod ? tick : warning}
                            />
                          )
                        }
                      />
                    ) : (
                      data.typeOfPayment === 'SWIFT' && (
                        <TextInput
                          placeholder={t(
                            'dynamicDiscount.newDynamicDiscount.swiftPlaceholder'
                          )}
                          title={t('dynamicDiscount.creditLineInfo.iban') + '*'}
                          maxLength={11}
                          value={data.swift}
                          onChange={(swift: string) => {
                            setErrorPaymentMethod(!ibantools.isValidBIC(swift));
                            updateData({ swift: swift.toUpperCase() });
                          }}
                          symbol={
                            data.swift &&
                            typeof errorPaymentMethod === 'boolean' && (
                              <img
                                alt="tick"
                                src={!errorPaymentMethod ? tick : warning}
                              />
                            )
                          }
                        />
                      )
                    )}
                  </div>

                  <div className="inputContainer" />
                  <div className="inputContainer" />
                </ContainerRow>
              )}
              {/* 5 ELEMENT FLAG REVOLVING*/}
              <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                <div className="customDropdown__title">
                  {t('moneylender.creditLine.flagRevolving') + '*'}
                </div>
              </div>
              <div className="createCreditLine__descriptionInput">
                {t('moneylender.creditLine.flagRevolvingDescription')}
              </div>
              <ContainerRow>
                <div className="inputContainer">
                  <MyDropdown
                    inputValue={
                      (typeof data.revolving === 'boolean' &&
                        (data.revolving
                          ? t('common.button.yes')
                          : t('common.button.no'))) ||
                      ''
                    }
                    links={visibilityInputValues}
                    onChange={revolving =>
                      updateData({
                        revolving: revolving === t('common.button.yes'),
                      })
                    }
                  />
                </div>
                <div className="inputContainer" />
                <div className="inputContainer" />
              </ContainerRow>

              {/* 6 ELEMENT FLAG WARNINGS*/}
              <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                <div className="customDropdown__title">
                  {t('moneylender.creditLine.flagWarnings') + '*'}
                </div>
              </div>
              <div className="createCreditLine__descriptionInput">
                <div className="createCreditLine__descriptionInput">
                  {t('moneylender.creditLine.flagWarningsDesc')}
                </div>
              </div>

              <ContainerRow>
                <div className="inputContainer">
                  <MyDropdown
                    inputValue={
                      data.warnings === 1
                        ? t('moneylender.warningDropdown.value1')
                        : data.warnings === 2
                        ? t('moneylender.warningDropdown.value2')
                        : ''
                    }
                    links={[
                      t('moneylender.warningDropdown.value1'),
                      t('moneylender.warningDropdown.value2'),
                    ]}
                    onChange={warning =>
                      updateData({
                        warnings:
                          warning === t('moneylender.warningDropdown.value1')
                            ? 1
                            : 2,
                      })
                    }
                  />
                </div>
                <div className="inputContainer" />
              </ContainerRow>

              {/* 7 ELEMENT DISCOUNT CALCULATION*/}
              <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                <div className="customDropdown__title">
                  {t('moneylender.creditLine.discountCalculation') + '*'}
                </div>
              </div>
              <div className="createCreditLine__descriptionInput">
                <ReactMarkdown
                  source={t(
                    'moneylender.creditLine.discountCalculationDescription'
                  )}
                  escapeHtml={false}
                />
              </div>
              <ContainerRow>
                <div className="inputContainer">
                  <MyDropdown
                    inputValue={
                      data.discount === 1
                        ? t('moneylender.creditLine.mode1')
                        : data.discount === 2
                        ? t('moneylender.creditLine.mode2')
                        : ''
                    }
                    links={discountModeInputValues}
                    onChange={value =>
                      updateData({
                        discount:
                          value === t('moneylender.creditLine.mode1')
                            ? 1
                            : value === t('moneylender.creditLine.mode2')
                            ? 2
                            : null,
                      })
                    }
                  />
                </div>
                <div className="inputContainer" />
                <div className="inputContainer" />
              </ContainerRow>

              {/* 9 ELEMENT PAYLABLES*/}
              <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                <div className="customDropdown__title">
                  {t('moneylender.creditLine.payables') + '*'}
                </div>
              </div>
              <div className="createCreditLine__descriptionInput">
                {t('moneylender.creditLine.payablesDesc')}
              </div>
              <ContainerRow>
                <div className="inputContainer">
                  <NumericInput
                    maxLength={3}
                    inputType={NumericInputType.INTEGER_NUMBER}
                    symbol={t('moneylender.creditLine.days')}
                    value={
                      data.durationPayables && data.durationPayables.toString()
                    }
                    placeholder={t(
                      'moneylender.creditLine.payablesPlaceholder'
                    )}
                    onChange={durationPayables =>
                      updateData({ durationPayables: +durationPayables })
                    }
                  />
                </div>
                <div className="inputContainer">
                  <div className="customDropdown__title">
                    {t('moneylender.payablesDropdown.title')}
                  </div>
                  <MyDropdown
                    inputValue={
                      data.durationPayablesType === 1
                        ? t('moneylender.payablesDropdown.value1')
                        : data.durationPayablesType === 2
                        ? t('moneylender.payablesDropdown.value2')
                        : ''
                    }
                    links={[
                      t('moneylender.payablesDropdown.value1'),
                      t('moneylender.payablesDropdown.value2'),
                    ]}
                    onChange={value =>
                      updateData({
                        durationPayablesType:
                          value === t('moneylender.payablesDropdown.value1')
                            ? 1
                            : value === t('moneylender.payablesDropdown.value2')
                            ? 2
                            : null,
                      })
                    }
                  />
                </div>
                <div className="inputContainer" />
              </ContainerRow>

              {/* 10 ELEMENT OPERATIONS*/}
              <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                <div className="customDropdown__title">
                  {t('moneylender.creditLine.operations') + '*'}
                </div>
              </div>
              <div className="createCreditLine__descriptionInput">
                <ReactMarkdown
                  source={t('moneylender.creditLine.operationDesc')}
                  escapeHtml={false}
                />
              </div>
              <ContainerRow>
                <div className="inputContainer">
                  <MyDropdown
                    inputValue={data.secondaryMarket}
                    links={[
                      SublimitType.PRIMARY_MARKET,
                      SublimitType.ALL,
                      SublimitType.SECONDARY_MARKET,
                    ]}
                    labelExtractor={sublimitType =>
                      t(`common.sublimitType.${sublimitType}`)
                    }
                    onChange={(sublimitType: SublimitType) =>
                      updateSublimitType(sublimitType)
                    }
                    disableSorting
                  />
                </div>
                <div className="inputContainer" />
                <div className="inputContainer" />
              </ContainerRow>

              {/* 10 ELEMENT SUBLIMIT */}
              {data.secondaryMarket === SublimitType.ALL && (
                <>
                  <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                    <div className="customDropdown__title">
                      {t('moneylender.creditLine.amountForMoneylender') + '*'}
                    </div>
                  </div>
                  <div className="createCreditLine__descriptionInput">
                    {t('moneylender.creditLine.amountForMoneylenderDesc')}
                  </div>
                  <ContainerRow>
                    <div className="inputContainer">
                      <NumericInput
                        inputType={NumericInputType.UNSIGNED_NUMBER}
                        disableInput={data.secondaryMarket !== SublimitType.ALL}
                        symbol={currencyUtils.getSymbolFromCurrencyISO(
                          data.currency
                        )}
                        value={data.sublimit}
                        placeholder={t(
                          'moneylender.creditLine.amountForMoneylenderPlaceholder'
                        )}
                        onBlur={handleSublimit}
                      />
                    </div>
                    <div className="inputContainer" />
                    <div className="inputContainer" />
                  </ContainerRow>
                </>
              )}

              {/* 11 ELEMENT AMOUNT FOR MONEYLENDER*/}
              <div className="customDropdown__titleContainer customDropdown__titleContainer--marginTop20">
                <div className="customDropdown__title">
                  {t('moneylender.creditLine.geographic') + '*'}
                </div>
              </div>
              <div className="createCreditLine__descriptionInput">
                {t('moneylender.creditLine.geographicDesc')}
              </div>
              <ContainerRow>
                <div className="inputContainer">
                  <MyDropdown
                    inputValue={
                      (typeof data.visibility === 'boolean' &&
                        (data.visibility
                          ? t('common.button.yes')
                          : t('common.button.no'))) ||
                      ''
                    }
                    links={[t('common.button.no'), t('common.button.yes')]}
                    onChange={geographic => {
                      updateData({
                        visibility: geographic === t('common.button.yes'),
                        moneylenderWhiteList:
                          geographic === t('common.button.no') && [],
                      });
                      initVendorsCountries();
                    }}
                  />
                </div>
                <div className="inputContainer" />
                <div className="inputContainer" />
              </ContainerRow>
              {data.visibility === true && (
                <ContainerRow style={{ flexDirection: 'column' }}>
                  <ScrollableTable
                    items={vendorCountryList.map(
                      ({ selected, country }: VendorCountryDTO) => ({
                        selected: selected,
                        countryName:
                          countryUtils.fromCodeToCountryName(country),
                      })
                    )}
                    description={t(
                      'dynamicDiscount.newDynamicDiscount.moneylendersTableDescription'
                    )}
                    emptyMessage={t('SCFProgram.table.noCountryAvailable')}
                    tableLabels={[
                      t(
                        'dynamicDiscount.moneylendersTable.country'
                      ).toUpperCase(),
                    ]}
                    onCheckboxClick={onCheckMoneylenderRow}
                  />
                </ContainerRow>
              )}
            </>
          )}
      </CardContainerWithButtons>
    </div>
  );
};

const mapStateToProps = (store: AppState) => {
  return {
    buyers: store?.company?.getBuyersForMoneylenderResponse,
    programs: store?.program?.getAllProgramsAvailableResponse?.programs,
    countryList: store?.program?.getAllProgramsAvailableResponse?.countryList,
    currencies: store?.program?.currencyEnabledResponse,
    allProgramsEnabled:
      store?.program?.getAllProgramsAvailableResponse?.allProgramsEnabled,
    isFetchingAllProgramsAvailable:
      store?.program?.isFetchingAllProgramsAvailable,
    creditLineDetails: store.moneylender.creditLineDetailsResponse,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getProgramsAvailable: (currency?: string, vatNumber?: string) =>
      dispatch(getProgramsAvailable(currency, vatNumber)),
    retrieveAllBuyer: () => dispatch(getAllBuyersForMoneylender()),
    getCurrencyEnabled: () => dispatch(getCurrencyEnabled()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCreditLine);

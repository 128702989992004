import {DefinitionRoles, LegalFilesDTO, Roles} from "./UserDTO";
import {PaymentStatus} from "./VendorDTO";

export interface InviteCustomerDTORequest {
    companyId: string
    email: string
    name: string
    roles: string[],
    isCompany: boolean,
}

export interface InviteCustomerDTOResponse {
    status: number
    errorMessage: string
}

export interface InviteCustomerDTOResponse {
    status: number
    errorMessage: string
}

export enum UsersSteps {
    NEW_USER = "newUser",
    CHOOSE_TYPE_OF_USER = "chooseTypeOfUser",
    CHOOSE_TYPE_OF_USER_COMPANY = "chooseTypeOfUserCompany",
    GET_COMPANY_DATA = "geCompanyData",
    SET_COMPANY_AND_RATE_DATA = "setCompanyAndRateData",
    INVITE_CUSTOMER = "inviteCustomer",
    INVITE_CUSTOMER_ROLES = "inviteCustomerBuyer",
    INVITE_SELECT_COMPANY = "inviteSetCompany",
    NEW_USER_PROCESS_COMPLETE = "processComplete",
    MODIFY_USER_TYPE = "modifyChooseTypeOfUser",
    MODIFY_INDIVIDUAL = "ModifyIndividual",
    MODIFY_COMPANY = "ModifyCompany",
}

export enum CompanyInfoSteps {
    COMPANY_LIST = "COMPANY_LIST",
    INFO = "INFO",
    USERS = "USERS",
    USER_INFO="USER_INFO"
}

export interface BlockedUserDTO {
    userId: string
    roles: Roles[]
    companyName: string[]
    companyCountry: string[]
    blockDate: Date
    vatNumber: string[]
}

export interface ModifyUserDTO {
    userId: string
    roles: Roles[]
    companyName: string
    companyCountry: string
    name: string
    companyId: string
}

export interface ModifyCompanyDTO {
    companyId: string
    role: Roles[]
    companyName: string
    companyCountry: string
    blocked: boolean
}

export interface PendingUserRequest {
    mail: string
}

export interface SignerDTO {
    name: string
    surname: string
    email: string
}

export interface CustomerDTO {
    role: string,
    businessName: string
    country: string
    date: Date
    vatNumber: string
    marketplaceCode: string
}

export interface OnBoardingStatusDTO {
    companyName: string
    name: string
    role: string
    step: string
    stepDate: Date
    type: string
    userId: string
}

export interface NewCustomerDTO {
    buyer?: NewCustomerHistory
    moneylender?: NewCustomerHistory
    vendor?: NewCustomerHistory
}

export interface NewCustomerHistory {
    month: string[]
    year: string[]
}

export interface InvoiceHistory {
    newPayables: InvoiceHistoryDetails
    creditCessionPayables: InvoiceHistoryDetails[],
    expiredPayables: InvoiceHistoryDetails[]
}

export interface InvoiceHistoryDetails {
    invoiceNumber: number
    invoiceAmount: number
    currency: string
    role: string
}

export interface InvoiceCessionHistoryDetails {
    invoiceNumber: number
    invoiceAmount: number
    currency: string
    cessionNumber: number
}

export interface StockCreditLine {
    buyer: string
    creditLine: string
    totalPrograms: number
    availability: number
}


export interface SapErrorDTO {
    count: number
    errors: ErrorDTO[]
}

export interface ErrorDTO {
    [index:string]:any
    id: number
    errorCode: string
    errorDescription: string
    sendDate: Date
    receiveDate: Date
    operationType: string
    operationId: string
    legalEntity: string
    fromCompanyBusinessName: string
    fromCompanyVatNumber: string
    fromCompanyMarketplaceCode: string
    fromCompanySapCode: string
    toCompanyBusinessName: string
    toCompanyVatNumber: string
    toCompanyMarketplaceCode: string
    toCompanySapCode: string
    affectedInvoice: string
}

export enum TariffPeriod {
    MONTH = "MONTH",
    THREE_MONTH = "THREE_MONTH",
    YEAR = "YEAR"
}

export interface FileToDownloadDTO {
    userId: string,
    documentType: string,
    documentId: string,
    name: string
    mime: string
}

export enum MimeTypes {
    PDF = "application/pdf",
    JPEG = 'image/jpeg',
    PNG = 'image/png'
}

export interface SwapUserRequestDTO{
    email: string
    swapRole: DefinitionRoles.LEGAL_REPRESENTATIVE | DefinitionRoles.EFFECTIVE_OWNER
    substitute?: string
    effectiveOwnerRelations: string
    legalFiles?: LegalFilesDTO[]
    startDuty: any
    endDuty: any
}

export interface AdminInvoiceDTO {
    documentId: string
    id: string[]
    buyer: string
    expiryDate: string
    effectiveExpireDate: string
    currency: string
    salable: boolean
    amount: number
    payableAmount: number
    creditor?: string
    currentCreditor?: string
    status: PaymentStatus
    cessionDate: string
}
